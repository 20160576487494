import React from "react";

const Group = () => {
  return (
    <svg width="98" height="98" viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M44.4778 0.957031H22.9798C21.6224 0.957031 20.5118 2.06757 20.5118 3.42502V18.2506C20.5118 19.608 21.6224 20.7186 22.9798 20.7186H35.6371C36.4257 20.7186 37.065 21.3579 37.065 22.1465V25.5424C37.065 26.1499 37.8295 26.4194 38.2104 25.9459L41.9892 21.2511C42.2602 20.9142 42.6694 20.7186 43.1016 20.7186H44.4778C45.8353 20.7186 46.9458 19.608 46.9458 18.2506V3.42502C46.9454 2.06757 45.8349 0.957031 44.4778 0.957031V0.957031Z" fill="#DAE6F1"/>
<path d="M53.5222 0.957031H75.0201C76.3776 0.957031 77.4881 2.06757 77.4881 3.42502V18.2506C77.4881 19.608 76.3776 20.7186 75.0201 20.7186H62.3628C61.5742 20.7186 60.9349 21.3579 60.9349 22.1465V25.5424C60.9349 26.1499 60.1705 26.4194 59.7896 25.9459L56.0108 21.2511C55.7398 20.9142 55.3306 20.7186 54.8984 20.7186H53.5222C52.1647 20.7186 51.0542 19.608 51.0542 18.2506V3.42502C51.0546 2.06757 52.1651 0.957031 53.5222 0.957031V0.957031Z" fill="#DAE6F1"/>
<path d="M95.7513 73.5106C95.6392 71.4993 94.2323 69.7946 92.2792 69.3031L85.5835 67.618C84.8312 67.4285 84.0905 67.1972 83.3624 66.9327L78.3575 72.7071C77.8951 73.2407 77.0869 73.2985 76.5533 72.8357L74.3223 70.9017C73.7886 70.4393 73.7308 69.6316 74.1936 69.0979L78.4991 64.1305C78.0367 63.4679 77.7722 62.6705 77.7722 61.8306V59.9326C76.1613 60.776 74.3338 61.256 72.3986 61.256C70.4635 61.256 68.636 60.7756 67.0251 59.9326V61.8306C67.0251 62.6847 66.7533 63.4962 66.2759 64.1654L70.5512 69.0979C71.014 69.6316 70.9562 70.4393 70.4226 70.9017L68.1915 72.8357C67.6579 73.2981 66.8502 73.2407 66.3873 72.7071L61.3955 66.948C60.6823 67.2053 59.9572 67.4308 59.2211 67.616L52.5177 69.3031C51.0052 69.684 49.8238 70.793 49.3139 72.2075C49.4108 72.5413 49.4735 72.8889 49.4934 73.2465L50.7973 95.7332C50.8386 96.4751 50.2483 97.0991 49.5053 97.0991H95.7636C96.4982 97.0991 97.082 96.482 97.041 95.7482L95.7513 73.5106Z" fill="#3CAADC"/>
<path d="M50.2024 85.4844L50.7969 95.7334C50.8383 96.4753 50.248 97.0993 49.5049 97.0993H56.3278V85.4844H50.2024Z" fill="#F9D0B4"/>
<path d="M96.4458 85.4844H88.6383V97.0993H95.7636C96.4982 97.0993 97.082 96.4822 97.0414 95.7487L96.4458 85.4844Z" fill="#F9D0B4"/>
<path d="M76.5533 72.836C77.087 73.2985 77.8947 73.241 78.3575 72.7074L83.3624 66.933C82.5371 66.6329 81.7278 66.2892 80.9411 65.896C80.2888 65.5702 79.7245 65.2835 79.6196 65.2161C79.1729 64.9283 78.7958 64.5569 78.4988 64.1309L74.1933 69.0982C73.7305 69.6319 73.7883 70.4396 74.3219 70.9024L76.5533 72.836Z" fill="#70D6F9"/>
<path d="M64.9265 65.3639C64.8722 65.3938 64.3715 65.6422 63.7731 65.9381C63.0002 66.3205 62.2051 66.6551 61.3951 66.9476L66.387 72.7066C66.8494 73.2403 67.6571 73.2981 68.1912 72.8353L70.4222 70.9013C70.9558 70.4389 71.0136 69.6311 70.5508 69.0971L66.2756 64.1646C65.9272 64.6538 65.4701 65.0668 64.9265 65.3639V65.3639Z" fill="#70D6F9"/>
<path d="M74.145 35.4408C69.8847 40.7982 63.6001 40.0422 61.487 39.6C61.103 39.5196 60.7347 39.8091 60.7343 40.2014V40.2095V49.5922C60.7343 56.0074 65.9831 61.2561 72.3983 61.2561C78.8134 61.2561 84.0622 56.0074 84.0622 49.5922V39.2019C84.0622 39.141 84.0614 39.0802 84.0603 39.0197C84.0538 38.6889 83.7888 38.4164 83.4581 38.4064C78.6125 38.2644 76.0595 36.3438 75.0783 35.3822C74.8107 35.1196 74.3782 35.1472 74.145 35.4408Z" fill="#F9D0B4"/>
<path d="M59.9856 40.7023C60.2405 40.7023 60.4897 40.7238 60.734 40.7617V40.2093C60.734 40.2066 60.734 40.2039 60.734 40.2012C60.7343 39.8088 61.1026 39.5194 61.4866 39.5998C63.5997 40.042 69.8843 40.798 74.1443 35.4406C74.3778 35.1469 74.81 35.1194 75.0779 35.382C76.0591 36.3436 78.6121 38.2642 83.4577 38.4062C83.7885 38.4158 84.0534 38.6887 84.0599 39.0195C84.061 39.0803 84.0618 39.1408 84.0618 39.2021V40.762C84.306 40.7241 84.5556 40.7027 84.8102 40.7027C86.1228 40.7027 87.3119 41.2245 88.1877 42.0693V33.3094C88.1877 29.6452 85.1896 26.647 81.5253 26.647C80.2823 26.647 79.1204 26.998 78.1217 27.5971C76.9553 25.6505 74.8303 24.3359 72.4078 24.3359H68.9866C62.3647 24.3359 56.9472 29.7539 56.9472 36.3754V41.77C57.7806 41.1035 58.8356 40.7023 59.9856 40.7023V40.7023Z" fill="#754E34"/>
<path d="M84.8106 40.7026C84.5556 40.7026 84.306 40.7241 84.0622 40.762V49.5919C84.0622 49.8549 84.0503 50.1152 84.0327 50.374C84.2865 50.415 84.5453 50.4418 84.8106 50.4418C87.5002 50.4418 89.6803 48.2616 89.6803 45.572C89.6803 42.8824 87.5002 40.7026 84.8106 40.7026V40.7026Z" fill="#F9D0B4"/>
<path d="M60.7344 49.5919C60.7344 48.3834 60.7344 43.1645 60.7344 40.762C60.4901 40.7241 60.2405 40.7026 59.986 40.7026C57.2963 40.7026 55.1162 42.8831 55.1162 45.5724C55.1162 48.2617 57.2963 50.4421 59.986 50.4421C60.2513 50.4421 60.51 50.415 60.7638 50.3744V50.374C60.7462 50.1152 60.7344 49.8549 60.7344 49.5919Z" fill="#F9D0B4"/>
<path d="M70.5508 69.0978C70.7361 69.3114 70.8341 69.5694 70.8552 69.8317H73.8886C73.9096 69.5698 74.0076 69.3118 74.1933 69.0978L78.4988 64.1304C78.0363 63.4678 77.7718 62.6704 77.7718 61.8305V59.9321C76.1609 60.7755 74.3334 61.2555 72.3983 61.2555C70.4632 61.2555 68.6356 60.7751 67.0247 59.9321V61.8305C67.0247 62.6846 66.7529 63.4961 66.2756 64.1653L70.5508 69.0978Z" fill="#F9D0B4"/>
<path d="M72.7264 54.6707C71.1786 54.6707 69.7228 54.0368 68.8324 52.9745C68.4928 52.5691 68.546 51.9657 68.9511 51.6262C69.3561 51.2866 69.9594 51.3391 70.2993 51.7449C70.8307 52.3784 71.7379 52.757 72.7264 52.757C73.7148 52.757 74.6217 52.3788 75.153 51.7449C75.4933 51.3395 76.0966 51.2874 76.5013 51.6262C76.9063 51.9661 76.9591 52.5694 76.6199 52.9745C75.7291 54.0364 74.2737 54.6707 72.7264 54.6707Z" fill="black"/>
<path d="M77.3036 47.678C78.0825 47.678 78.7139 47.0466 78.7139 46.2677C78.7139 45.4888 78.0825 44.8574 77.3036 44.8574C76.5247 44.8574 75.8933 45.4888 75.8933 46.2677C75.8933 47.0466 76.5247 47.678 77.3036 47.678Z" fill="black"/>
<path d="M67.8317 47.678C68.6106 47.678 69.242 47.0466 69.242 46.2677C69.242 45.4888 68.6106 44.8574 67.8317 44.8574C67.0528 44.8574 66.4214 45.4888 66.4214 46.2677C66.4214 47.0466 67.0528 47.678 67.8317 47.678Z" fill="black"/>
<path d="M67.3543 74.1041C66.7281 74.1044 66.1049 73.843 65.6635 73.3338L61.0961 68.064C60.5414 68.2478 59.9913 68.4086 59.4543 68.5441L52.7508 70.2315C51.6587 70.5064 50.7403 71.2877 50.2935 72.3213C50.0838 72.8067 49.5203 73.0299 49.0352 72.8201C48.5502 72.6103 48.3267 72.0468 48.5364 71.5618C49.2178 69.9858 50.6186 68.7944 52.2834 68.3753L58.9868 66.6882C59.6636 66.5179 60.365 66.3023 61.0705 66.0481C61.4433 65.9126 61.8595 66.0225 62.1179 66.3215L67.1097 72.0805C67.2265 72.2149 67.4301 72.2294 67.5641 72.113L69.7948 70.1795C69.9291 70.0627 69.9437 69.859 69.8273 69.7247L65.5524 64.7925C65.263 64.4587 65.2397 63.9702 65.4962 63.6104C65.8702 63.0856 66.0677 62.47 66.0677 61.8307V60.229C66.0677 59.7003 66.4961 59.272 67.0247 59.272C67.5534 59.272 67.9818 59.7003 67.9818 60.229V61.8307C67.9818 62.6128 67.8003 63.3719 67.4527 64.0625L71.2736 68.4713C72.0809 69.4035 71.9799 70.8184 71.0485 71.6253L68.8175 73.5593C68.3952 73.9249 67.8738 74.1041 67.3543 74.1041V74.1041Z" fill="black"/>
<path d="M95.7632 98.0557H49.0329C48.5043 98.0557 48.0759 97.6274 48.0759 97.0987C48.0759 96.57 48.5043 96.1417 49.0329 96.1417H95.7632C95.8838 96.1417 95.9623 96.0785 95.9975 96.0406C96.0335 96.0027 96.0921 95.9216 96.0852 95.801L94.7955 73.5657C94.7063 71.9621 93.6011 70.6226 92.045 70.231L85.3496 68.5459C84.7949 68.4062 84.2287 68.2396 83.6599 68.0498L79.0799 73.3337C78.2733 74.2651 76.858 74.3662 75.9263 73.5592L73.6949 71.6248C72.7635 70.8175 72.6624 69.4026 73.4694 68.4712L77.3255 64.0222C76.9893 63.3419 76.8144 62.5958 76.8144 61.8302V60.2285C76.8144 59.6999 77.2428 59.2715 77.7714 59.2715C78.3001 59.2715 78.7285 59.6999 78.7285 60.2285V61.8302C78.7285 62.4569 78.9203 63.0629 79.2828 63.5823C79.5335 63.9414 79.5083 64.4257 79.2215 64.7568L74.9164 69.7249C74.8 69.8589 74.8146 70.0626 74.949 70.1793L77.18 72.1133C77.3136 72.2289 77.5172 72.2148 77.6344 72.0804L82.6393 66.306C82.8988 66.0063 83.3172 65.8976 83.689 66.0335C84.4067 66.2946 85.1226 66.5154 85.8174 66.6904L92.5128 68.3751C94.8851 68.9727 96.571 71.015 96.7069 73.4574L97.9966 95.693C98.0306 96.3067 97.809 96.9119 97.3883 97.3563C96.9676 97.8008 96.3754 98.0557 95.7632 98.0557V98.0557Z" fill="black"/>
<path d="M88.6383 97.7429C88.1096 97.7429 87.6813 97.3145 87.6813 96.7858V77.7593C87.6813 77.2306 88.1096 76.8022 88.6383 76.8022C89.167 76.8022 89.5953 77.2306 89.5953 77.7593V96.7858C89.5953 97.3145 89.167 97.7429 88.6383 97.7429Z" fill="black"/>
<path d="M56.3278 97.7429C55.7992 97.7429 55.3708 97.3145 55.3708 96.7858V77.7593C55.3708 77.2306 55.7992 76.8022 56.3278 76.8022C56.8565 76.8022 57.2849 77.2306 57.2849 77.7593V96.7858C57.2849 97.3145 56.8561 97.7429 56.3278 97.7429Z" fill="black"/>
<path d="M56.0981 86.4414H50.356C49.8273 86.4414 49.3989 86.013 49.3989 85.4844C49.3989 84.9557 49.8273 84.5273 50.356 84.5273H56.0981C56.6268 84.5273 57.0552 84.9557 57.0552 85.4844C57.0552 86.013 56.6268 86.4414 56.0981 86.4414Z" fill="black"/>
<path d="M96.1499 86.4414H88.8921C88.3635 86.4414 87.9351 86.013 87.9351 85.4844C87.9351 84.9557 88.3635 84.5273 88.8921 84.5273H96.1499C96.6785 84.5273 97.1069 84.9557 97.1069 85.4844C97.1069 86.013 96.6785 86.4414 96.1499 86.4414Z" fill="black"/>
<path d="M83.3624 67.8905C83.2541 67.8905 83.1438 67.8717 83.0355 67.8327C82.1615 67.5153 81.3128 67.152 80.5131 66.7524C79.2816 66.1368 79.1691 66.0645 79.1021 66.0212C78.5566 65.6702 78.0895 65.2185 77.7132 64.6783C77.4108 64.245 77.5172 63.6486 77.951 63.3461C78.3847 63.0445 78.9807 63.1501 79.2831 63.5839C79.5163 63.918 79.8038 64.1971 80.1376 64.4119C80.1617 64.426 80.3562 64.5344 81.3683 65.0405C82.1022 65.4072 82.8827 65.7414 83.6886 66.0342C84.1854 66.2149 84.4419 66.7635 84.2612 67.2604C84.1204 67.6486 83.7532 67.8905 83.3624 67.8905V67.8905Z" fill="black"/>
<path d="M61.3951 67.9053C61.0035 67.9053 60.6356 67.6626 60.4947 67.2729C60.3152 66.7756 60.5728 66.2274 61.0701 66.0479C61.8617 65.7623 62.6281 65.4365 63.349 65.0809C63.9151 64.8007 64.3929 64.5637 64.4733 64.5216C64.871 64.3046 65.2266 63.988 65.4965 63.6097C65.8039 63.1795 66.4011 63.0796 66.8314 63.3866C67.2617 63.6936 67.3616 64.2912 67.0546 64.7214C66.6174 65.3339 66.0405 65.8461 65.3855 66.2041C65.3553 66.2205 65.2963 66.2531 64.1973 66.7967C63.4121 67.1845 62.5787 67.5386 61.7197 67.8482C61.6125 67.8869 61.503 67.9053 61.3951 67.9053V67.9053Z" fill="black"/>
<path d="M72.3983 62.2132C65.4387 62.2132 59.7769 56.5514 59.7769 49.5922V40.2091C59.7773 39.7272 59.9894 39.2842 60.3584 38.9845C60.7313 38.6825 61.2117 38.5649 61.6834 38.6633C63.9201 39.1323 69.5383 39.6961 73.3959 34.8451C73.6757 34.493 74.093 34.2755 74.5405 34.2476C74.9911 34.2177 75.4279 34.3839 75.7483 34.6985C76.5464 35.4806 78.8981 37.3147 83.4861 37.4494C84.3275 37.4739 84.9997 38.1553 85.0169 38.9998L85.0192 39.2015V49.5922C85.0192 56.5514 79.3574 62.2132 72.3983 62.2132V62.2132ZM61.691 40.6141V49.5922C61.691 55.496 66.4942 60.2991 72.3983 60.2991C78.302 60.2991 83.1052 55.496 83.1052 49.5922V39.3505C78.5715 39.1441 75.95 37.4636 74.6699 36.3113C70.3005 41.5252 64.0652 41.0364 61.691 40.6141V40.6141ZM74.8942 36.0365C74.8938 36.0368 74.8935 36.0372 74.8935 36.0376C74.8935 36.0372 74.8938 36.0368 74.8942 36.0365ZM74.1451 35.4408H74.1489H74.1451Z" fill="black"/>
<path d="M88.1882 42.8388C87.6595 42.8388 87.2311 42.4104 87.2311 41.8818V33.3098C87.2311 30.1635 84.6713 27.6044 81.5257 27.6044C80.5089 27.6044 79.5018 27.8858 78.614 28.4183C78.3966 28.5484 78.1351 28.5875 77.8894 28.5254C77.6428 28.4638 77.4315 28.3069 77.301 28.089C76.2678 26.3645 74.3931 25.293 72.4086 25.293H68.9871C62.8762 25.293 57.9046 30.2646 57.9046 36.3754V41.6146C57.9046 42.1432 57.4763 42.5716 56.9476 42.5716C56.4189 42.5716 55.9906 42.1432 55.9906 41.6146V36.3754C55.9906 29.2091 61.8208 23.3789 68.9871 23.3789H72.4086C74.7614 23.3789 76.9959 24.5013 78.4322 26.3572C79.4072 25.9185 80.4615 25.6899 81.5257 25.6899C85.7274 25.6899 89.1452 29.1081 89.1452 33.3094V41.8818C89.1452 42.4104 88.7168 42.8388 88.1882 42.8388V42.8388Z" fill="black"/>
<path d="M84.8106 51.3995C84.6027 51.3995 84.391 51.3865 84.164 51.3593C83.6392 51.2965 83.2644 50.8203 83.3272 50.2954C83.39 49.7706 83.865 49.3962 84.391 49.4586C84.5422 49.4766 84.6793 49.4854 84.8106 49.4854C86.9681 49.4854 88.7233 47.7302 88.7233 45.5723C88.7233 43.4148 86.9681 41.6596 84.8106 41.6596C84.6938 41.6596 84.579 41.6657 84.4657 41.6756C83.9408 41.7246 83.4742 41.3342 83.4267 40.8078C83.3796 40.2814 83.7682 39.8163 84.2945 39.7689C84.4645 39.7535 84.6368 39.7451 84.811 39.7451C88.0239 39.7451 90.6378 42.359 90.6378 45.5719C90.6374 48.7852 88.0231 51.3995 84.8106 51.3995V51.3995Z" fill="black"/>
<path d="M59.9856 51.3996C56.7726 51.3996 54.1588 48.7857 54.1588 45.5724C54.1588 42.3594 56.7726 39.7456 59.9856 39.7456C60.1582 39.7456 60.3289 39.7536 60.4974 39.7693C61.0237 39.8172 61.4115 40.2827 61.3637 40.8091C61.3162 41.3354 60.8511 41.729 60.324 41.6754C60.2126 41.6654 60.1 41.6601 59.9856 41.6601C57.828 41.6601 56.0728 43.4152 56.0728 45.5728C56.0728 47.7307 57.828 49.4859 59.9856 49.4859C60.1038 49.4859 60.2198 49.4786 60.3347 49.4667C60.8652 49.4086 61.3311 49.7941 61.3855 50.3197C61.4406 50.8453 61.0586 51.3161 60.5326 51.3705C60.3427 51.3896 60.1636 51.3996 59.9856 51.3996Z" fill="black"/>
<path d="M73.6198 70.7886H71.0757C70.547 70.7886 70.1186 70.3602 70.1186 69.8315C70.1186 69.3029 70.547 68.8745 71.0757 68.8745H73.6198C74.1485 68.8745 74.5769 69.3029 74.5769 69.8315C74.5769 70.3602 74.1485 70.7886 73.6198 70.7886Z" fill="black"/>
<path d="M37.7181 27.1452C37.5382 27.1452 37.3556 27.1146 37.1768 27.0518C36.5276 26.8229 36.108 26.2303 36.108 25.542V22.1461C36.108 21.8862 35.8967 21.6752 35.6371 21.6752H22.9794C21.091 21.6752 19.5548 20.1386 19.5548 18.2506V3.42464C19.5548 1.53661 21.0914 0 22.9794 0H44.4774C46.3662 0 47.9025 1.53661 47.9025 3.42464V18.2502C47.9025 20.1386 46.3658 21.6748 44.4774 21.6748H43.1012C42.958 21.6748 42.8241 21.7388 42.7341 21.8506L38.9554 26.5458C38.643 26.9339 38.1905 27.1452 37.7181 27.1452ZM22.9794 1.91406C22.1464 1.91406 21.4689 2.59202 21.4689 3.42464V18.2502C21.4689 19.0832 22.1468 19.7608 22.9794 19.7608H35.6368C36.9521 19.7608 38.0217 20.8307 38.0217 22.1457V24.6527L41.243 20.6504C41.6978 20.085 42.375 19.7608 43.1012 19.7608H44.4774C45.3108 19.7608 45.9884 19.0828 45.9884 18.2502V3.42464C45.9884 2.59164 45.3104 1.91406 44.4774 1.91406H22.9794Z" fill="black"/>
<path d="M60.2819 27.1452C59.8095 27.1452 59.357 26.9339 59.0446 26.5458L55.2659 21.8506C55.1759 21.7388 55.0419 21.6748 54.8988 21.6748H53.5226C51.6338 21.6748 50.0975 20.1382 50.0975 18.2502V3.42464C50.0975 1.53623 51.6341 0 53.5226 0H75.0205C76.909 0 78.4452 1.53661 78.4452 3.42464V18.2502C78.4452 20.1386 76.9086 21.6748 75.0205 21.6748H62.3632C62.1033 21.6748 61.8924 21.8862 61.8924 22.1457V25.5416C61.8924 26.2299 61.4728 26.8229 60.8236 27.0514C60.6444 27.1146 60.4614 27.1452 60.2819 27.1452ZM53.5222 1.91406C52.6888 1.91406 52.0112 2.59202 52.0112 3.42464V18.2502C52.0112 19.0832 52.6892 19.7608 53.5222 19.7608H54.8984C55.6242 19.7608 56.3014 20.0854 56.7566 20.6504L59.9779 24.6527V22.1457C59.9779 20.8304 61.0479 19.7608 62.3628 19.7608H75.0202C75.8532 19.7608 76.5307 19.0828 76.5307 18.2502V3.42464C76.5307 2.59164 75.8528 1.91406 75.0202 1.91406H53.5222Z" fill="black"/>
<path d="M26.5335 12.8762C27.6198 12.8762 28.5004 11.9956 28.5004 10.9093C28.5004 9.82299 27.6198 8.94238 26.5335 8.94238C25.4472 8.94238 24.5666 9.82299 24.5666 10.9093C24.5666 11.9956 25.4472 12.8762 26.5335 12.8762Z" fill="#70D6F9"/>
<path d="M33.7755 12.8762C34.8618 12.8762 35.7424 11.9956 35.7424 10.9093C35.7424 9.82299 34.8618 8.94238 33.7755 8.94238C32.6893 8.94238 31.8087 9.82299 31.8087 10.9093C31.8087 11.9956 32.6893 12.8762 33.7755 12.8762Z" fill="#70D6F9"/>
<path d="M41.0172 12.8762C42.1035 12.8762 42.9841 11.9956 42.9841 10.9093C42.9841 9.82299 42.1035 8.94238 41.0172 8.94238C39.931 8.94238 39.0504 9.82299 39.0504 10.9093C39.0504 11.9956 39.931 12.8762 41.0172 12.8762Z" fill="#70D6F9"/>
<path d="M26.5335 13.8336C24.9211 13.8336 23.6092 12.5221 23.6092 10.9097C23.6092 9.29725 24.9211 7.98535 26.5335 7.98535C28.1459 7.98535 29.4574 9.29725 29.4574 10.9097C29.4574 12.5221 28.1459 13.8336 26.5335 13.8336ZM26.5335 9.89941C25.9765 9.89941 25.5233 10.3527 25.5233 10.9097C25.5233 11.4663 25.9765 11.9195 26.5335 11.9195C27.0901 11.9195 27.5434 11.4663 27.5434 10.9097C27.5434 10.3527 27.0905 9.89941 26.5335 9.89941V9.89941Z" fill="black"/>
<path d="M33.7752 13.8336C32.1628 13.8336 30.8513 12.5221 30.8513 10.9097C30.8513 9.29725 32.1628 7.98535 33.7752 7.98535C35.3876 7.98535 36.6991 9.29725 36.6991 10.9097C36.6991 12.5221 35.3876 13.8336 33.7752 13.8336ZM33.7752 9.89941C33.2186 9.89941 32.7653 10.3527 32.7653 10.9097C32.7653 11.4663 33.2186 11.9195 33.7752 11.9195C34.3318 11.9195 34.785 11.4663 34.785 10.9097C34.785 10.3527 34.3322 9.89941 33.7752 9.89941V9.89941Z" fill="black"/>
<path d="M41.0172 13.8336C39.4048 13.8336 38.0929 12.5221 38.0929 10.9097C38.0929 9.29725 39.4048 7.98535 41.0172 7.98535C42.6296 7.98535 43.9411 9.29725 43.9411 10.9097C43.9411 12.5221 42.6296 13.8336 41.0172 13.8336ZM41.0172 9.89941C40.4602 9.89941 40.007 10.3527 40.007 10.9097C40.007 11.4663 40.4602 11.9195 41.0172 11.9195C41.5738 11.9195 42.0271 11.4663 42.0271 10.9097C42.0271 10.3527 41.5742 9.89941 41.0172 9.89941V9.89941Z" fill="black"/>
<path d="M57.1585 12.8762C58.2448 12.8762 59.1254 11.9956 59.1254 10.9093C59.1254 9.82299 58.2448 8.94238 57.1585 8.94238C56.0722 8.94238 55.1916 9.82299 55.1916 10.9093C55.1916 11.9956 56.0722 12.8762 57.1585 12.8762Z" fill="#70D6F9"/>
<path d="M64.4006 12.8762C65.4869 12.8762 66.3675 11.9956 66.3675 10.9093C66.3675 9.82299 65.4869 8.94238 64.4006 8.94238C63.3143 8.94238 62.4337 9.82299 62.4337 10.9093C62.4337 11.9956 63.3143 12.8762 64.4006 12.8762Z" fill="#70D6F9"/>
<path d="M71.6422 12.8762C72.7285 12.8762 73.6091 11.9956 73.6091 10.9093C73.6091 9.82299 72.7285 8.94238 71.6422 8.94238C70.5559 8.94238 69.6753 9.82299 69.6753 10.9093C69.6753 11.9956 70.5559 12.8762 71.6422 12.8762Z" fill="#70D6F9"/>
<path d="M57.1589 13.8336C55.5465 13.8336 54.2346 12.5221 54.2346 10.9097C54.2346 9.29725 55.5465 7.98535 57.1589 7.98535C58.7713 7.98535 60.0828 9.29725 60.0828 10.9097C60.0828 12.5221 58.7709 13.8336 57.1589 13.8336ZM57.1589 9.89941C56.6019 9.89941 56.1486 10.3527 56.1486 10.9097C56.1486 11.4663 56.6019 11.9195 57.1589 11.9195C57.7155 11.9195 58.1687 11.4663 58.1687 10.9097C58.1683 10.3527 57.7155 9.89941 57.1589 9.89941V9.89941Z" fill="black"/>
<path d="M64.4002 13.8336C62.7878 13.8336 61.4763 12.5221 61.4763 10.9097C61.4763 9.29725 62.7878 7.98535 64.4002 7.98535C66.0126 7.98535 67.3241 9.29725 67.3241 10.9097C67.3241 12.5221 66.0126 13.8336 64.4002 13.8336ZM64.4002 9.89941C63.8436 9.89941 63.3903 10.3527 63.3903 10.9097C63.3903 11.4663 63.8436 11.9195 64.4002 11.9195C64.9568 11.9195 65.41 11.4663 65.41 10.9097C65.41 10.3527 64.9572 9.89941 64.4002 9.89941V9.89941Z" fill="black"/>
<path d="M71.6422 13.8336C70.0298 13.8336 68.7179 12.5221 68.7179 10.9097C68.7179 9.29725 70.0298 7.98535 71.6422 7.98535C73.2546 7.98535 74.5661 9.29725 74.5661 10.9097C74.5661 12.5221 73.2546 13.8336 71.6422 13.8336ZM71.6422 9.89941C71.0852 9.89941 70.632 10.3527 70.632 10.9097C70.632 11.4663 71.0852 11.9195 71.6422 11.9195C72.1988 11.9195 72.6521 11.4663 72.6521 10.9097C72.6521 10.3527 72.1992 9.89941 71.6422 9.89941V9.89941Z" fill="black"/>
<path d="M25.663 85.4216C25.7097 85.5682 25.7464 85.7179 25.7778 85.8683C25.7843 85.8997 25.7905 85.9315 25.7962 85.9629C25.8241 86.1122 25.8467 86.2626 25.8597 86.4138V86.4142C25.8708 86.5428 25.8774 86.6715 25.8774 86.8008C25.8774 86.6715 25.8842 86.5428 25.895 86.4142C25.908 86.2626 25.9306 86.1126 25.9585 85.9633C25.9643 85.9315 25.9704 85.9001 25.9769 85.8687C26.0087 85.7182 26.045 85.569 26.0917 85.422L32.7596 64.5487C31.8542 63.785 31.3106 62.6526 31.3106 61.4361V59.4282C29.6408 60.3374 27.7306 60.8546 25.7055 60.8546C23.8171 60.8546 22.0301 60.4021 20.4433 59.6043V61.4357C20.4433 62.6527 19.8997 63.785 18.9944 64.5483L25.663 85.4216Z" fill="#3CAADC"/>
<path d="M27.2118 67.9166L31.7107 63.1873C31.4538 62.6499 31.3118 62.0538 31.3118 61.4356V59.4277C29.6419 60.3369 27.7317 60.8541 25.7066 60.8541C23.8182 60.8541 22.0312 60.4016 20.4445 59.6038V61.4352C20.4445 62.0538 20.3028 62.6495 20.0456 63.1869L24.5444 67.9162C24.6765 68.0552 24.7354 68.2362 24.7259 68.4146C25.0497 68.4418 25.3759 68.4602 25.7066 68.4602C26.1538 68.4602 26.5928 68.4269 27.0273 68.3779C27.0273 68.2121 27.089 68.046 27.2118 67.9166V67.9166Z" fill="#F9D0B4"/>
<path d="M31.7103 63.1875L27.2115 67.9168C26.9673 68.1732 26.9646 68.5752 27.2046 68.8355L29.7897 71.6362C29.9593 71.8199 30.199 71.8903 30.4256 71.857L32.7604 64.5484C32.3155 64.1729 31.9591 63.7077 31.7103 63.1875V63.1875Z" fill="#70D6F9"/>
<path d="M20.0456 63.1875C19.7968 63.7077 19.4404 64.1729 18.9952 64.5484L21.33 71.857C21.5566 71.8903 21.7962 71.8199 21.9658 71.6362L24.5509 68.8355C24.791 68.5752 24.7883 68.1732 24.544 67.9168L20.0456 63.1875Z" fill="#70D6F9"/>
<path d="M25.9781 85.8677C26.0098 85.7173 26.0462 85.568 26.0929 85.421L28.6918 77.2851L27.4691 71.0073C27.004 71.2883 26.4608 71.4529 25.8778 71.4529C25.2948 71.4529 24.7515 71.2883 24.2864 71.0073L23.0637 77.2851L25.6626 85.421C25.7093 85.5676 25.7461 85.7173 25.7775 85.8677C25.784 85.8991 25.7901 85.9309 25.7959 85.9623C25.8238 86.1116 25.8464 86.262 25.8594 86.4132V86.4136C25.8705 86.5422 25.877 86.6709 25.877 86.8003C25.877 86.6709 25.8839 86.5422 25.8946 86.4136C25.9076 86.262 25.9302 86.112 25.9582 85.9627C25.9654 85.9309 25.9712 85.8995 25.9781 85.8677V85.8677Z" fill="#FC657E"/>
<path d="M27.205 68.8354C27.0855 68.7064 27.0266 68.5422 27.0269 68.3779C26.5925 68.4273 26.1534 68.4602 25.7062 68.4602C25.3755 68.4602 25.0493 68.4419 24.7255 68.4147C24.7174 68.5659 24.6612 68.7156 24.5509 68.835L23.3516 70.135C23.9093 70.9317 24.8315 71.4538 25.8777 71.4538C26.924 71.4538 27.8462 70.9317 28.4039 70.135L27.205 68.8354Z" fill="#F98FA6"/>
<path d="M25.8777 86.8008C25.8777 86.3327 25.8054 85.8675 25.663 85.4216L18.9952 64.5483C18.7463 64.7581 18.4715 64.9419 18.1714 65.09L14.9339 66.6863L5.77397 68.992C3.79866 69.4893 2.37613 71.2131 2.26282 73.247L0.958956 95.7338C0.917612 96.4757 1.50791 97.0996 2.25095 97.0996H25.8777V86.8008Z" fill="#DAE6F1"/>
<path d="M14.5105 71.0864L16.83 72.0143C17.1554 72.1445 17.2939 72.5296 17.1259 72.837L15.626 75.5867C15.502 75.8145 15.5426 76.0966 15.7259 76.28L25.8597 86.4138C25.831 86.0769 25.7656 85.7439 25.6626 85.4212L18.9952 64.5483C18.7463 64.7581 18.4715 64.9419 18.1713 65.09L14.9339 66.6863L14.8298 66.7124L14.1522 70.4391C14.1028 70.7128 14.2525 70.983 14.5105 71.0864V71.0864Z" fill="#EDF4FC"/>
<path d="M9.54199 97.7517C9.01333 97.7517 8.58496 97.3233 8.58496 96.7947V78.8423C8.58496 78.3136 9.01333 77.8853 9.54199 77.8853C10.0707 77.8853 10.499 78.3136 10.499 78.8423V96.7947C10.499 97.323 10.0707 97.7517 9.54199 97.7517V97.7517Z" fill="black"/>
<path d="M32.7604 65.505C32.5426 65.505 32.3232 65.4311 32.1437 65.2795C31.0067 64.3206 30.3548 62.9191 30.3548 61.4349V59.7402C30.3548 59.2116 30.7832 58.7832 31.3118 58.7832C31.8405 58.7832 32.2689 59.2116 32.2689 59.7402V61.4349C32.2689 62.3537 32.6731 63.2219 33.3779 63.8164C33.7817 64.1575 33.8326 64.7612 33.4923 65.1651C33.3028 65.3894 33.0326 65.505 32.7604 65.505Z" fill="black"/>
<path d="M18.9956 65.5048C18.7234 65.5048 18.4531 65.3892 18.264 65.1649C17.9229 64.7606 17.9739 64.1569 18.3781 63.8166C19.0832 63.2213 19.4871 62.3535 19.4871 61.4348V59.8794C19.4871 59.3507 19.9155 58.9224 20.4441 58.9224C20.9728 58.9224 21.4012 59.3507 21.4012 59.8794V61.4348C21.4012 62.9185 20.7492 64.3196 19.6123 65.279C19.4331 65.4309 19.2134 65.5048 18.9956 65.5048Z" fill="black"/>
<path d="M30.1691 72.8069C30.1048 72.8069 30.0397 72.8 29.9742 72.7866C29.6312 72.7157 29.3242 72.542 29.0865 72.2839L26.5017 69.4844C25.9214 68.8551 25.929 67.877 26.5182 67.2568L31.017 62.5276C31.2337 62.2994 31.5503 62.1907 31.86 62.242C32.1712 62.291 32.438 62.4901 32.5735 62.7741C32.7676 63.1795 33.0382 63.5302 33.3782 63.8169C33.7824 64.158 33.833 64.7617 33.4926 65.1655C33.1512 65.5694 32.5483 65.6203 32.144 65.28C31.9388 65.1066 31.7489 64.919 31.5759 64.7177L28.09 68.3827L30.4447 70.9334C30.9148 71.0743 31.2065 71.5535 31.1058 72.0431C31.0116 72.4956 30.6135 72.8065 30.1691 72.8069V72.8069Z" fill="black"/>
<path d="M21.6236 72.7985C21.1987 72.7985 20.8105 72.5129 20.6983 72.0822C20.5716 71.5964 20.8419 71.1011 21.3074 70.938L23.6663 68.3823L20.1808 64.7181C20.0073 64.9187 19.8179 65.1066 19.6127 65.28C19.2088 65.6207 18.6051 65.5694 18.264 65.1656C17.9233 64.7613 17.9742 64.1576 18.3785 63.8169C18.7188 63.5302 18.9891 63.1795 19.1824 62.7745C19.3179 62.4905 19.5851 62.2914 19.8959 62.242C20.2076 62.1907 20.5226 62.2991 20.7393 62.5276L25.2381 67.2569C25.828 67.8774 25.8353 68.8563 25.2542 69.4845L22.6694 72.2844C22.4535 72.519 22.1752 72.6863 21.8659 72.7667C21.7847 72.7885 21.7036 72.7985 21.6236 72.7985V72.7985Z" fill="black"/>
<path d="M28.6627 78.098C28.2141 78.098 27.8133 77.7811 27.7245 77.3244L26.5657 71.3759C26.4646 70.8572 26.8034 70.3545 27.3221 70.2531C27.8443 70.1524 28.3438 70.4908 28.4449 71.0095L29.6037 76.958C29.7047 77.4767 29.366 77.9794 28.8472 78.0808C28.7848 78.0923 28.7232 78.098 28.6627 78.098V78.098Z" fill="black"/>
<path d="M23.084 78.1462C23.0231 78.1462 22.9619 78.1404 22.9003 78.1286C22.3816 78.0271 22.0428 77.5249 22.1434 77.0062L23.3141 70.9972C23.4151 70.4781 23.9212 70.1416 24.4365 70.2403C24.9552 70.3418 25.294 70.844 25.1933 71.3628L24.0227 77.3718C23.9335 77.8292 23.5327 78.1462 23.084 78.1462V78.1462Z" fill="black"/>
<path d="M25.7063 69.4177C25.4211 69.4177 25.1236 69.4059 24.7967 69.381C24.2696 69.3412 23.8749 68.8814 23.9143 68.3543C23.9541 67.8271 24.4128 67.4328 24.941 67.4719C25.2193 67.4933 25.4697 67.5033 25.7063 67.5033C26.0374 67.5033 26.3942 67.4826 26.7969 67.4405C27.326 67.3846 27.7938 67.7666 27.8485 68.2926C27.904 68.8182 27.522 69.2891 26.9964 69.3442C26.527 69.3936 26.1048 69.4177 25.7063 69.4177V69.4177Z" fill="black"/>
<path d="M25.8782 72.4106C24.6826 72.4106 23.5549 71.8839 22.7843 70.9655C22.4443 70.5605 22.4972 69.9572 22.9026 69.6176C23.3068 69.2777 23.9113 69.3309 24.2504 69.7355C24.6566 70.2198 25.2496 70.4969 25.8782 70.4969C26.5079 70.4969 27.1016 70.2183 27.5082 69.7328C27.8477 69.3271 28.4514 69.2746 28.8561 69.613C29.2615 69.9522 29.3151 70.5559 28.9759 70.9609C28.2049 71.882 27.076 72.4106 25.8782 72.4106Z" fill="black"/>
<path d="M25.8781 98.0556H2.25094C1.63538 98.0556 1.03896 97.7991 0.615952 97.3516C0.192944 96.9041 -0.0302356 96.2946 0.00345186 95.6798L1.30769 73.1907C1.44474 70.7273 3.14634 68.6663 5.54083 68.063L14.6024 65.7826L17.7487 64.231C17.9692 64.1219 18.1813 63.9826 18.3784 63.8164C18.6231 63.6101 18.9542 63.54 19.2604 63.6281C19.5675 63.7161 19.8105 63.9523 19.9074 64.2567L26.5752 85.1295C26.7479 85.6704 26.8355 86.2328 26.8355 86.8001V97.0981C26.8352 97.6272 26.4064 98.0556 25.8781 98.0556V98.0556ZM18.4592 66.0149L15.3573 67.5443C15.2968 67.5741 15.2333 67.5975 15.1678 67.6143L6.00787 69.9196C4.42991 70.3166 3.30904 71.6748 3.21869 73.2991L1.91445 95.7882C1.90718 95.9118 1.96919 95.9968 2.00594 96.0358C2.04346 96.0753 2.12462 96.1411 2.25094 96.1411H24.9211V86.8001C24.9211 86.4303 24.8641 86.064 24.7515 85.7118L18.4592 66.0149Z" fill="black"/>
<path d="M25.7679 87.2785C25.5229 87.2785 25.2782 87.1851 25.0911 86.9982L15.0499 76.9571C14.5691 76.4763 14.4611 75.724 14.7869 75.128L16.0854 72.7473L14.1553 71.9751C13.4777 71.7041 13.0803 70.986 13.2109 70.2678L13.8482 66.7635C13.9428 66.2437 14.4397 65.8991 14.9611 65.9929C15.4809 66.0875 15.8262 66.5859 15.7317 67.1058L15.1487 70.3107L17.186 71.1257C17.5948 71.2895 17.9149 71.621 18.0642 72.0356C18.2131 72.4498 18.1775 72.9092 17.9665 73.2955L16.6003 75.8006L26.4447 85.645C26.8187 86.019 26.8187 86.6246 26.4447 86.9982C26.2575 87.1851 26.0125 87.2785 25.7679 87.2785V87.2785Z" fill="black"/>
<path d="M25.8777 86.8008C25.8777 86.3327 25.9501 85.8675 26.0925 85.4216L32.7603 64.5483C33.0092 64.7581 33.284 64.9419 33.5841 65.09L36.8216 66.6863L45.9815 68.992C47.9568 69.4893 49.3794 71.2131 49.4927 73.247L50.7965 95.7338C50.8379 96.4757 50.2476 97.0996 49.5046 97.0996H25.8777V86.8008Z" fill="#DAE6F1"/>
<path d="M37.2454 71.0863L34.9259 72.0142C34.6005 72.1444 34.4619 72.5295 34.63 72.8369L36.1298 75.5866C36.2539 75.8144 36.2133 76.0965 36.0299 76.2799L25.8961 86.4137C25.9248 86.0768 25.9903 85.7438 26.0933 85.4211L32.7611 64.5479C33.0099 64.7576 33.2848 64.9414 33.5849 65.0895L36.8224 66.6859L36.9265 66.7119L37.6041 70.4386C37.6531 70.7127 37.5034 70.9829 37.2454 71.0863V71.0863Z" fill="#EDF4FC"/>
<path d="M42.2139 97.7517C41.6852 97.7517 41.2568 97.3233 41.2568 96.7947V78.8423C41.2568 78.3136 41.6852 77.8853 42.2139 77.8853C42.7425 77.8853 43.1709 78.3136 43.1709 78.8423V96.7947C43.1709 97.323 42.7425 97.7517 42.2139 97.7517V97.7517Z" fill="black"/>
<path d="M49.5045 98.056H25.8781C25.3494 98.056 24.9211 97.6276 24.9211 97.0989V86.8009C24.9211 86.2336 25.0087 85.6712 25.1814 85.1303L31.8492 64.2575C31.9465 63.9531 32.1895 63.7173 32.4962 63.6289C32.8024 63.5412 33.1336 63.6109 33.3782 63.8172C33.5757 63.9838 33.787 64.1231 34.0079 64.2318L37.1542 65.7834L46.2158 68.0638C48.6103 68.6667 50.3115 70.7281 50.4489 73.1935L51.7528 95.6779C51.7873 96.2947 51.5645 96.9041 51.1411 97.3516C50.7169 97.7995 50.1213 98.056 49.5045 98.056V98.056ZM26.8351 96.1419H49.5049C49.6313 96.1419 49.7128 96.0757 49.7503 96.0366C49.7874 95.9972 49.8487 95.9126 49.8414 95.787L48.5376 73.3022C48.4472 71.6756 47.3263 70.3174 45.7484 69.9204L36.5884 67.6151C36.523 67.5983 36.4594 67.5753 36.399 67.5451L33.297 66.0157L27.0047 85.713C26.8922 86.0651 26.8351 86.4315 26.8351 86.8013V96.1419Z" fill="black"/>
<path d="M25.988 87.2784C25.743 87.2784 25.4983 87.185 25.3111 86.9981C24.9371 86.6241 24.9371 86.0185 25.3111 85.6449L35.1559 75.8005L33.7897 73.295C33.5787 72.9091 33.5431 72.4497 33.6921 72.0355C33.8414 71.621 34.1614 71.2894 34.5702 71.1256L36.6076 70.3106L36.0245 67.1057C35.93 66.5858 36.2749 66.0874 36.7951 65.9928C37.3158 65.8994 37.8134 66.2432 37.908 66.7634L38.5454 70.2677C38.6759 70.9859 38.2785 71.704 37.601 71.9751L35.6708 72.7472L36.9697 75.1287C37.2951 75.7255 37.1868 76.4769 36.7063 76.957L26.6648 86.9981C26.4776 87.185 26.2326 87.2784 25.988 87.2784V87.2784ZM36.6619 70.6099H36.6657H36.6619Z" fill="black"/>
<path d="M35.816 34.9523C35.0446 34.1434 33.7909 34.0692 32.9116 34.759C31.5216 35.85 29.1164 37.1986 25.7193 37.1986C22.3138 37.1986 19.8959 35.8435 18.5002 34.7509C17.6216 34.0634 16.3702 34.1407 15.6008 34.9477L15.5966 34.9519C14.5151 36.0862 13.9118 37.5933 13.9118 39.1606V49.0593C13.9118 55.5465 19.2195 60.8538 25.7063 60.8538C32.1934 60.8538 37.5007 55.5461 37.5007 49.0593V39.1606C37.5011 37.5933 36.8978 36.0866 35.816 34.9523Z" fill="#F9D0B4"/>
<path d="M38.2579 40.0708C38.0003 40.0708 37.748 40.0926 37.5011 40.1309V49.06C37.5011 49.3261 37.4889 49.5891 37.4713 49.8509C37.7281 49.8922 37.9896 49.9194 38.2579 49.9194C40.9774 49.9194 43.1824 47.7148 43.1824 44.9949C43.1824 42.275 40.9774 40.0708 38.2579 40.0708V40.0708Z" fill="#F9D0B4"/>
<path d="M13.9118 49.0595C13.9118 47.8376 13.9118 42.5601 13.9118 40.1304C13.6649 40.0921 13.4126 40.0703 13.155 40.0703C10.4355 40.0703 8.23047 42.2749 8.23047 44.9948C8.23047 47.7147 10.4351 49.9193 13.155 49.9193C13.4233 49.9193 13.6848 49.8921 13.9416 49.8508V49.8504C13.924 49.5886 13.9118 49.3256 13.9118 49.0595Z" fill="#F9D0B4"/>
<path d="M37.4571 30.0095C37.062 30.0095 36.7198 29.7048 36.7144 29.3097C36.6237 22.7376 29.1186 21.503 21.6446 22.7935C15.3079 23.8875 10.6763 29.3828 10.6763 35.8133V40.7443C11.4048 40.3182 12.25 40.0702 13.155 40.0702C13.4126 40.0702 13.6649 40.092 13.9118 40.1303V39.1606C13.9118 37.5934 14.5151 36.0862 15.5966 34.952L15.6008 34.9478C16.3706 34.1404 17.622 34.0635 18.5002 34.751C19.8959 35.8435 22.3142 37.1987 25.7193 37.1987C29.1163 37.1987 31.5216 35.85 32.9115 34.759C33.7905 34.0688 35.0446 34.1435 35.8159 34.9523C36.8974 36.0866 37.5007 37.5938 37.5007 39.161V40.1307C37.7476 40.0924 37.9999 40.0706 38.2575 40.0706C39.1732 40.0706 40.0277 40.3251 40.7623 40.7608C40.7623 39.3846 40.7623 37.9712 40.7623 36.7121C40.7627 30.8268 38.6377 30.0095 37.4571 30.0095V30.0095Z" fill="#754E34"/>
<path d="M25.8781 54.5014C24.3159 54.5014 22.847 53.8613 21.949 52.7891C21.6094 52.3837 21.6626 51.78 22.068 51.4412C22.4742 51.1016 23.0771 51.1552 23.4159 51.5603C23.9549 52.2038 24.8756 52.5877 25.8781 52.5877C26.8803 52.5877 27.801 52.2038 28.3404 51.5603C28.6807 51.1552 29.2836 51.1028 29.6887 51.4416C30.0937 51.7815 30.1465 52.3848 29.8073 52.7898C28.9085 53.8617 27.4396 54.5014 25.8781 54.5014V54.5014Z" fill="black"/>
<path d="M25.7063 61.8116C18.6752 61.8116 12.9544 56.0912 12.9544 49.0597V39.1613C12.9544 37.3395 13.6469 35.6107 14.9037 34.2919C16.0181 33.1243 17.8157 32.9999 19.0901 33.9975C20.3982 35.0212 22.6105 36.2416 25.7193 36.2416C28.822 36.2416 31.022 35.0258 32.3205 34.006C33.5972 33.0041 35.3976 33.127 36.5085 34.2915C37.7653 35.6099 38.4578 37.3391 38.4578 39.1609V49.0593C38.4581 56.0908 32.7378 61.8116 25.7063 61.8116V61.8116ZM17.1531 35.2417C16.8373 35.2417 16.5257 35.3657 16.2937 35.6092C15.3734 36.5739 14.8689 37.8341 14.8689 39.1613V49.0597C14.8689 55.0354 19.7306 59.8975 25.7067 59.8975C31.6827 59.8975 36.5445 55.0358 36.5445 49.0597V39.1613C36.5445 37.8337 36.0403 36.5735 35.1238 35.613C34.697 35.1655 34.0003 35.1219 33.503 35.5119C31.9668 36.7178 29.3694 38.1556 25.7201 38.1556C22.0657 38.1556 19.4565 36.714 17.9111 35.5047C17.6844 35.3282 17.4172 35.2417 17.1531 35.2417V35.2417Z" fill="black"/>
<path d="M38.2579 50.8762C38.0673 50.8762 37.8755 50.8654 37.6715 50.8432C37.1459 50.7866 36.7661 50.3142 36.8228 49.789C36.8794 49.2634 37.3533 48.8836 37.877 48.9403C38.0125 48.9548 38.137 48.9621 38.2579 48.9621C40.4453 48.9621 42.225 47.1824 42.225 44.995C42.225 42.8076 40.4453 41.0279 38.2579 41.0279C38.1266 41.0279 37.9911 41.0352 37.8544 41.0494C37.3258 41.1091 36.8584 40.7224 36.8036 40.1965C36.7489 39.6705 37.1305 39.2 37.6565 39.1456C41.1803 38.7804 44.1395 41.5551 44.1395 44.995C44.1395 48.2382 41.5011 50.8762 38.2579 50.8762Z" fill="black"/>
<path d="M13.155 50.8761C9.91178 50.8761 7.27344 48.2377 7.27344 44.9949C7.27344 41.7521 9.91216 39.1138 13.155 39.1138C13.3173 39.1138 13.4781 39.121 13.6369 39.1344C14.1637 39.1792 14.5541 39.6424 14.5094 40.1692C14.4646 40.6959 14.0021 41.0845 13.4746 41.0416C13.3697 41.0328 13.2625 41.0278 13.155 41.0278C10.9672 41.0278 9.1875 42.8075 9.1875 44.9949C9.1875 47.1823 10.9672 48.962 13.155 48.962C13.2778 48.962 13.4069 48.9543 13.5485 48.9387C14.0756 48.8831 14.5465 49.2602 14.6043 49.7858C14.6621 50.3114 14.2824 50.7838 13.7571 50.8416C13.5458 50.865 13.3487 50.8761 13.155 50.8761Z" fill="black"/>
<path d="M40.7627 41.603C40.234 41.603 39.8056 41.1747 39.8056 40.646V36.7122C39.8056 33.061 38.9497 30.9666 37.4567 30.9666C36.5322 30.9666 35.77 30.2297 35.757 29.3236C35.7329 27.5772 35.138 26.2442 33.9379 25.2478C31.6533 23.3513 27.2322 22.8001 21.8073 23.7376C15.912 24.7555 11.6329 29.8346 11.6329 35.8142V40.6162C11.6329 41.1448 11.2046 41.5732 10.6759 41.5732C10.1472 41.5732 9.71887 41.1448 9.71887 40.6162V35.8142C9.71887 28.9002 14.666 23.0282 21.4816 21.8511C24.9954 21.2451 31.4427 20.6881 35.1606 23.7751C36.7378 25.0847 37.5792 26.8617 37.6646 29.0583C39.2701 29.1471 41.7197 30.33 41.7197 36.7122V40.6456C41.7197 41.1747 41.2913 41.603 40.7627 41.603V41.603Z" fill="black"/>
<path d="M30.6671 47.1242C31.4549 47.1242 32.0935 46.4856 32.0935 45.6978C32.0935 44.9101 31.4549 44.2715 30.6671 44.2715C29.8794 44.2715 29.2408 44.9101 29.2408 45.6978C29.2408 46.4856 29.8794 47.1242 30.6671 47.1242Z" fill="black"/>
<path d="M21.0888 47.1242C21.8765 47.1242 22.5151 46.4856 22.5151 45.6978C22.5151 44.9101 21.8765 44.2715 21.0888 44.2715C20.301 44.2715 19.6624 44.9101 19.6624 45.6978C19.6624 46.4856 20.301 47.1242 21.0888 47.1242Z" fill="black"/>
<path d="M11.7906 18.7004C10.0679 18.3941 8.7281 17.0543 8.38357 15.3699C8.38357 15.2934 8.23044 15.2934 8.23044 15.3699C7.88591 17.0543 6.54607 18.3941 4.82341 18.7004C4.74685 18.7004 4.74685 18.8152 4.82341 18.8535C6.54607 19.1598 7.88591 20.4996 8.23044 22.184C8.23044 22.2605 8.38357 22.2605 8.38357 22.184C8.7281 20.4996 10.0679 19.1598 11.7906 18.8535C11.8672 18.8152 11.8672 18.7004 11.7906 18.7004V18.7004Z" fill="#F5C84C"/>
<path d="M86.8625 17.3512C85.7871 17.1601 84.9503 16.3233 84.7355 15.2717C84.7355 15.2239 84.6398 15.2239 84.6398 15.2717C84.4247 16.3233 83.5883 17.1598 82.5129 17.3512C82.4651 17.3512 82.4651 17.4228 82.5129 17.4469C83.5883 17.6379 84.4251 18.4747 84.6398 19.5263C84.6398 19.5742 84.7355 19.5742 84.7355 19.5263C84.9507 18.4747 85.7871 17.6383 86.8625 17.4469C86.9103 17.4228 86.9103 17.3512 86.8625 17.3512Z" fill="#FC657E"/>
<path d="M91.7602 57.6133H90.8797V56.7328C90.8797 56.082 90.3437 55.5078 89.6547 55.5078C88.9656 55.5078 88.4297 56.0437 88.4297 56.7328V57.6133H87.5492C86.8984 57.6133 86.3242 58.1492 86.3242 58.8383C86.3242 59.5273 86.8602 60.0633 87.5492 60.0633H88.4297V60.9437C88.4297 61.5945 88.9656 62.1687 89.6547 62.1687C90.3437 62.1687 90.8797 61.6328 90.8797 60.9437V60.0633H91.7602C92.4109 60.0633 92.9852 59.5273 92.9852 58.8383C92.9852 58.1492 92.4492 57.6133 91.7602 57.6133Z" fill="#70D6F9"/>
<path d="M14.0875 10.2594C14.8063 10.2594 15.3891 9.67665 15.3891 8.95781C15.3891 8.23898 14.8063 7.65625 14.0875 7.65625C13.3687 7.65625 12.7859 8.23898 12.7859 8.95781C12.7859 9.67665 13.3687 10.2594 14.0875 10.2594Z" fill="#87D147"/>
<path d="M96.6984 66.15C97.4173 66.15 98 65.5673 98 64.8484C98 64.1296 97.4173 63.5469 96.6984 63.5469C95.9796 63.5469 95.3969 64.1296 95.3969 64.8484C95.3969 65.5673 95.9796 66.15 96.6984 66.15Z" fill="#F5C84C"/>
</svg>
  );
};

export default Group;
