import React from "react";

const Psychiatrist = () => {
  return (
    <svg width="98" height="98" viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M52.8134 63.8666L50.1876 61.4691C49.9445 61.2473 49.5687 61.2605 49.3421 61.4988L44.2781 66.8223C44.0295 67.0838 44.0263 67.4932 44.271 67.7582L46.9042 70.6109C47.2064 70.9383 47.728 70.9241 48.0118 70.5806L52.8715 64.6979C53.0768 64.4496 53.051 64.0839 52.8134 63.8666V63.8666Z" fill="#EDF4FC"/>
<path d="M33.0257 63.8666L35.6515 61.4691C35.8945 61.2473 36.2703 61.2605 36.4969 61.4988L41.561 66.8223C41.8095 67.0838 41.8128 67.4932 41.5681 67.7582L38.9348 70.6109C38.6327 70.9383 38.111 70.9241 37.8272 70.5806L32.9672 64.6979C32.7619 64.4496 32.7877 64.0839 33.0257 63.8666Z" fill="#EDF4FC"/>
<path d="M55.025 94.4072V65.353L54.7025 65.2717C54.0879 65.117 53.491 64.9001 52.9177 64.6299C52.9031 64.6532 52.8893 64.677 52.8715 64.6987L48.0118 70.5814C47.7281 70.9248 47.2064 70.9394 46.9042 70.6117L44.271 67.759C44.0263 67.4939 44.0295 67.0846 44.2781 66.8231L48.7672 62.1039C48.4399 61.6051 48.2523 61.0156 48.2523 60.3958V56.9956C46.6641 57.8614 44.8463 58.354 42.9194 58.354C40.9922 58.354 39.1747 57.8614 37.5864 56.9956V60.3958C37.5864 61.0156 37.3989 61.6051 37.0715 62.1039L41.5606 66.8231C41.8092 67.0846 41.8124 67.4939 41.5677 67.759L38.9345 70.6117C38.6324 70.939 38.1107 70.9248 37.8269 70.5814L32.9673 64.6983C32.9492 64.6767 32.9353 64.6531 32.9211 64.6296C32.3481 64.8998 31.7512 65.1167 31.1362 65.2713L23.1197 67.2951C21.1686 67.7861 19.7637 69.4889 19.6517 71.4978L18.7656 95.8441C18.7249 96.5765 19.3079 97.1931 20.0417 97.1931H27.1404C27.6175 97.1931 28.0046 96.8064 28.0046 96.3289V81.8219C28.0046 81.6976 28.1795 81.6711 28.2167 81.7896L29.2581 85.1259C30.0777 87.7514 30.4945 90.4859 30.4945 93.2367V96.3289C30.4945 96.8061 30.8812 97.1931 31.3587 97.1931H41.1545H44.6852H54.4811C54.9582 97.1931 55.3453 96.8064 55.3453 96.3289V95.6949C55.1416 95.3092 55.025 94.8714 55.025 94.4072V94.4072Z" fill="#DAE6F1"/>
<path d="M37.5864 60.3958C37.5864 61.0156 37.3988 61.6051 37.0715 62.1039L41.5606 66.8231C41.6555 66.9229 41.7139 67.0443 41.7371 67.1711H44.1011C44.1244 67.0446 44.1828 66.9229 44.2777 66.8231L48.7668 62.1039C48.4395 61.6051 48.2519 61.0156 48.2519 60.3958V56.9956C46.6637 57.8614 44.8459 58.354 42.919 58.354C40.9918 58.354 39.1743 57.8614 37.586 56.9956V60.3958H37.5864Z" fill="#F9D0B4"/>
<path d="M47.7677 83.9575H38.0716C37.5399 83.9575 37.1089 84.3885 37.1089 84.9202V96.2304C37.1089 96.7621 37.5399 97.193 38.0716 97.193H47.7677C48.2994 97.193 48.7304 96.7621 48.7304 96.2304V84.9202C48.7304 84.3885 48.2994 83.9575 47.7677 83.9575V83.9575Z" fill="#EDF4FC"/>
<path d="M47.7677 83.9575H38.0716C37.5399 83.9575 37.1089 84.3885 37.1089 84.9202V87.5085H48.7304V84.9202C48.7304 84.3885 48.2994 83.9575 47.7677 83.9575V83.9575Z" fill="#70D6F9"/>
<path d="M54.851 38.5967C54.606 38.5967 54.3661 38.6173 54.1314 38.6538V47.1416C54.1314 47.3944 54.1198 47.6446 54.103 47.8935C54.3471 47.9329 54.5956 47.9587 54.8507 47.9587C57.4361 47.9587 59.5318 45.863 59.5318 43.2775C59.5318 40.6924 57.4361 38.5967 54.851 38.5967Z" fill="#F9D0B4"/>
<path d="M48.2823 34.935C46.5746 36.928 40.5195 42.9751 31.7076 40.4648V47.142C31.7076 53.3084 36.7529 58.3541 42.9197 58.3541C49.0861 58.3541 54.1318 53.3088 54.1318 47.142V38.0837C54.1318 37.6718 53.8435 37.3225 53.4422 37.2295C52.3075 36.9668 50.9301 36.3095 49.3512 34.8749C49.0383 34.5912 48.5567 34.6144 48.2823 34.935V34.935Z" fill="#F9D0B4"/>
<path d="M31.7072 40.4644C30.9373 40.2453 30.1467 39.9612 29.3358 39.6032C28.8131 39.3724 28.2024 39.4786 27.7853 39.8695C26.8759 40.7221 26.3065 41.9323 26.3065 43.2775C26.3065 45.8626 28.4022 47.9586 30.9876 47.9586C31.2426 47.9586 31.4912 47.9328 31.7353 47.8934C31.7185 47.6445 31.7069 47.3944 31.7069 47.1416V40.4644H31.7072Z" fill="#F9D0B4"/>
<path d="M27.7856 39.8702C28.2023 39.4793 28.8131 39.3731 29.3361 39.6039C30.147 39.9619 30.9376 40.2457 31.7075 40.4652C40.5194 42.9754 46.5748 36.9284 48.2822 34.9353C48.5566 34.6151 49.0383 34.5915 49.3504 34.8753C50.9296 36.3102 52.3071 36.9671 53.4415 37.2299C53.8427 37.3229 54.131 37.6722 54.131 38.0841V38.6542C54.3657 38.6177 54.6056 38.597 54.8506 38.597C56.1457 38.597 57.3179 39.1232 58.1653 39.9732V37.2312C58.1653 28.8457 51.3044 21.9849 42.9189 21.9849C34.5334 21.9849 27.6726 28.8457 27.6726 37.2312V39.9864C27.711 39.9483 27.7462 39.907 27.7856 39.8702V39.8702Z" fill="#FCDD86"/>
<path d="M54.8509 47.959C54.5959 47.959 54.3473 47.9328 54.1033 47.8938C53.8399 51.8166 51.5385 55.2043 48.2526 56.9953V57.8091H53.0884C57.1891 57.8091 60.5132 54.485 60.5132 50.3843C60.5132 48.6088 59.8892 46.9799 58.8497 45.7021C58.0284 47.0535 56.5474 47.959 54.8509 47.959V47.959Z" fill="#FCDD86"/>
<path d="M32.7506 57.8088H37.5864V56.9949C34.3004 55.204 31.9991 51.8163 31.7357 47.8934C31.4916 47.9328 31.243 47.9586 30.988 47.9586C29.2916 47.9586 27.8102 47.0531 26.9893 45.7021C25.9498 46.9799 25.3258 48.6088 25.3258 50.3843C25.3258 54.4847 28.6502 57.8088 32.7506 57.8088V57.8088Z" fill="#FCDD86"/>
<path d="M42.9193 53.3723C41.3776 53.3723 39.9294 52.7428 39.0462 51.6888C38.7598 51.3469 38.8047 50.8382 39.1466 50.5518C39.4881 50.2655 39.9972 50.3104 40.2832 50.6519C40.8636 51.3447 41.8492 51.7582 42.9193 51.7582C43.9895 51.7582 44.9754 51.3447 45.5558 50.6519C45.8421 50.31 46.3519 50.2658 46.6924 50.5518C47.0343 50.8382 47.0788 51.3469 46.7928 51.6888C45.9096 52.7431 44.4614 53.3723 42.9193 53.3723V53.3723Z" fill="black"/>
<path d="M47.7975 46.0264C48.5997 46.0264 49.2501 45.3761 49.2501 44.5738C49.2501 43.7715 48.5997 43.1211 47.7975 43.1211C46.9952 43.1211 46.3448 43.7715 46.3448 44.5738C46.3448 45.3761 46.9952 46.0264 47.7975 46.0264Z" fill="black"/>
<path d="M38.0415 46.0264C38.8438 46.0264 39.4942 45.3761 39.4942 44.5738C39.4942 43.7715 38.8438 43.1211 38.0415 43.1211C37.2393 43.1211 36.5889 43.7715 36.5889 44.5738C36.5889 45.3761 37.2393 46.0264 38.0415 46.0264Z" fill="black"/>
<path d="M54.851 48.7661C54.686 48.7661 54.5208 48.7574 54.3451 48.7397C53.9019 48.6945 53.5788 48.2987 53.624 47.8552C53.6692 47.4116 54.0633 47.0875 54.5085 47.1337C54.6215 47.1453 54.7351 47.1521 54.8513 47.1521C56.9874 47.1521 58.7255 45.414 58.7255 43.2779C58.7255 41.1418 56.9874 39.4041 54.8513 39.4041C54.7503 39.4041 54.6508 39.4083 54.5524 39.4164C54.1079 39.4506 53.7192 39.1207 53.6834 38.6765C53.6479 38.2323 53.9791 37.843 54.4233 37.8075C54.5647 37.7962 54.707 37.79 54.8513 37.79C57.8774 37.79 60.3395 40.2518 60.3395 43.2779C60.3392 46.304 57.8771 48.7661 54.851 48.7661Z" fill="black"/>
<path d="M42.9194 59.1611C36.2923 59.1611 30.9006 53.7695 30.9006 47.142V40.4649C30.9006 40.2118 31.0194 39.9732 31.2215 39.8208C31.4235 39.6678 31.686 39.6194 31.9284 39.6888C40.2958 42.0722 46.0923 36.2508 47.6696 34.4101C47.9437 34.0902 48.3256 33.8997 48.7456 33.8736C49.1636 33.8474 49.582 33.9946 49.8935 34.2777C51.1693 35.437 52.4244 36.1656 53.6243 36.4435C54.3981 36.623 54.9388 37.2974 54.9388 38.0838V47.142C54.9385 53.7695 49.5468 59.1611 42.9194 59.1611V59.1611ZM32.5143 41.5005V47.142C32.5143 52.8794 37.1819 57.5471 42.9191 57.5471C48.6565 57.5471 53.3241 52.8794 53.3241 47.142V38.0838C53.3241 38.0473 53.2915 38.0234 53.2592 38.016C51.8017 37.6783 50.319 36.8358 48.8508 35.5112C47.1215 37.5127 41.17 43.3986 32.5143 41.5005V41.5005Z" fill="black"/>
<path d="M30.988 48.766C27.9619 48.766 25.4998 46.3042 25.4998 43.2778C25.4998 41.7709 26.1319 40.314 27.2337 39.281C27.889 38.6663 28.8419 38.503 29.6622 38.8652C30.4147 39.1974 31.1769 39.4743 31.9284 39.6884C32.3571 39.8104 32.6057 40.2568 32.4836 40.6855C32.3619 41.1142 31.9152 41.3631 31.4865 41.2408C30.6639 41.0064 29.8307 40.7039 29.0101 40.3417C28.7838 40.2417 28.5201 40.2875 28.3377 40.4583C27.5487 41.1982 27.1139 42.1996 27.1139 43.2778C27.1139 45.4138 28.8519 47.1519 30.988 47.1519C31.0845 47.1519 31.1801 47.1471 31.2747 47.1387C31.7108 47.1009 32.1101 47.4282 32.1486 47.8727C32.1873 48.3166 31.859 48.7079 31.4148 48.7469C31.274 48.7589 31.1317 48.766 30.988 48.766Z" fill="black"/>
<path d="M27.673 40.6196C27.2272 40.6196 26.866 40.2583 26.866 39.8125V37.2316C26.866 28.3796 34.0677 21.1782 42.9193 21.1782C51.7713 21.1782 58.9727 28.3796 58.9727 37.2316V39.7318C58.9727 40.1776 58.6115 40.5389 58.1657 40.5389C57.7199 40.5389 57.3586 40.1776 57.3586 39.7318V37.2316C57.3586 29.2696 50.8813 22.7923 42.9193 22.7923C34.9577 22.7923 28.4801 29.2696 28.4801 37.2316V39.8125C28.4801 40.2583 28.1188 40.6196 27.673 40.6196V40.6196Z" fill="black"/>
<path d="M53.0881 58.6163H48.55C48.1042 58.6163 47.7429 58.2551 47.7429 57.8093C47.7429 57.3635 48.1042 57.0022 48.55 57.0022H53.0881C56.7372 57.0022 59.7059 54.0336 59.7059 50.3845C59.7059 48.9357 59.2455 47.5595 58.3749 46.4051C58.1066 46.049 58.1777 45.5432 58.5334 45.2749C58.8888 45.0067 59.3953 45.0777 59.6636 45.4334C60.747 46.8703 61.32 48.5822 61.32 50.3848C61.32 54.9236 57.6269 58.6163 53.0881 58.6163V58.6163Z" fill="black"/>
<path d="M37.3623 58.6163H32.7506C28.2114 58.6163 24.5187 54.9236 24.5187 50.3845C24.5187 48.5441 25.1137 46.8032 26.239 45.3498C26.5115 44.9973 27.0186 44.9324 27.3712 45.2059C27.7237 45.4786 27.7882 45.9858 27.5151 46.338C26.6106 47.5063 26.1328 48.9057 26.1328 50.3848C26.1328 54.034 29.1015 57.0026 32.7506 57.0026H37.3623C37.8082 57.0026 38.1694 57.3638 38.1694 57.8096C38.1694 58.2554 37.8082 58.6163 37.3623 58.6163Z" fill="black"/>
<path d="M47.4446 71.6553C47.0146 71.6553 46.6036 71.4759 46.3108 71.1589L43.6779 68.3064C43.1466 67.7302 43.153 66.8344 43.6931 66.2669L48.7571 60.9433C49.2833 60.391 50.1682 60.36 50.7315 60.8736L53.3573 63.2711C53.9135 63.7789 53.9736 64.6312 53.4942 65.2119L48.6342 71.0953C48.3504 71.4384 47.9323 71.6424 47.4872 71.6547C47.473 71.6553 47.4588 71.6553 47.4446 71.6553V71.6553ZM44.9418 67.296L47.4413 70.0045L52.1264 64.3332L49.7898 62.1997L44.9418 67.296ZM52.2688 64.463C52.2691 64.463 52.2691 64.463 52.2688 64.463V64.463Z" fill="black"/>
<path d="M38.3941 71.6547C38.3799 71.6547 38.366 71.6547 38.3518 71.6541C37.9063 71.6418 37.4882 71.4381 37.2045 71.0943L32.3445 65.2116C31.8648 64.6312 31.9248 63.7786 32.4813 63.2705L35.1071 60.873C35.6692 60.359 36.5553 60.3897 37.0812 60.9427L42.1455 66.2663C42.6853 66.8344 42.6917 67.7302 42.1607 68.3058L39.5275 71.1585C39.2356 71.4752 38.8241 71.6547 38.3941 71.6547V71.6547ZM33.7122 64.3323L38.397 70.0032L40.8969 67.2951L36.0491 62.1991L33.7122 64.3323ZM33.5702 64.4623C33.5699 64.4623 33.5699 64.4623 33.5702 64.4623V64.4623ZM33.0256 63.8664H33.0288H33.0256Z" fill="black"/>
<path d="M42.9193 82.0202C42.4735 82.0202 42.1123 81.6589 42.1123 81.2131V70.7768C42.1123 70.331 42.4735 69.9697 42.9193 69.9697C43.3652 69.9697 43.7264 70.331 43.7264 70.7768V81.2131C43.7264 81.6589 43.3652 82.0202 42.9193 82.0202Z" fill="black"/>
<path d="M37.1932 62.7087C37.0606 62.7087 36.9266 62.676 36.8023 62.6073C36.4127 62.391 36.2719 61.9 36.4879 61.51C36.6787 61.1666 36.7794 60.7808 36.7794 60.3953V57.2172C36.7794 56.7714 37.1406 56.4102 37.5864 56.4102C38.0322 56.4102 38.3935 56.7714 38.3935 57.2172V60.3953C38.3935 61.0542 38.2227 61.7105 37.8996 62.2932C37.752 62.5589 37.4767 62.7087 37.1932 62.7087Z" fill="black"/>
<path d="M48.6723 62.7559C48.3937 62.7559 48.1222 62.6113 47.9727 62.3524C47.628 61.7548 47.4453 61.0782 47.4453 60.3955V57.1737C47.4453 56.7279 47.8065 56.3667 48.2523 56.3667C48.6981 56.3667 49.0593 56.7279 49.0593 57.1737V60.3955C49.0593 60.7951 49.1668 61.1928 49.3705 61.5453C49.5933 61.9311 49.4612 62.425 49.0752 62.6478C48.9483 62.721 48.8095 62.7559 48.6723 62.7559Z" fill="black"/>
<path d="M54.7034 66.0784C54.6382 66.0784 54.5721 66.0707 54.5059 66.0539C53.9206 65.9067 53.337 65.7033 52.7704 65.4499C52.3637 65.2678 52.1816 64.7904 52.3633 64.3836C52.5457 63.9769 53.0229 63.7948 53.4299 63.9769C53.9106 64.1922 54.4052 64.3643 54.9 64.4889C55.3323 64.5977 55.5944 65.0364 55.4856 65.4686C55.393 65.834 55.0644 66.0784 54.7034 66.0784V66.0784Z" fill="black"/>
<path d="M54.4804 97.9999H31.3583C30.4369 97.9999 29.687 97.2503 29.687 96.3287V93.2361C29.687 90.9557 29.3929 88.6918 28.8112 86.4908V96.3284C28.8112 97.2497 28.0616 97.9996 27.14 97.9996H20.0413C19.4708 97.9996 18.9185 97.762 18.5269 97.3472C18.1347 96.9327 17.9281 96.3681 17.9594 95.7986L18.8449 71.4676C18.9779 69.0778 20.6162 67.0921 22.9224 66.5117L30.9383 64.4883C31.4202 64.3669 31.9022 64.2003 32.3713 63.9927C32.779 63.812 33.2554 63.9966 33.4359 64.404C33.6164 64.8114 33.432 65.2882 33.0246 65.4687C32.472 65.713 31.9028 65.91 31.3331 66.0533L23.3172 68.0767C21.6989 68.4841 20.55 69.8764 20.4574 71.5418L19.5719 95.8726C19.5615 96.0643 19.6474 96.1828 19.6997 96.238C19.752 96.2932 19.8653 96.3858 20.0416 96.3858H27.1403C27.172 96.3858 27.1975 96.3603 27.1975 96.3287V81.8216C27.1975 81.3658 27.5248 80.9852 27.9755 80.9164C28.4229 80.8474 28.8516 81.1137 28.9868 81.5495L30.0286 84.8851C30.8731 87.5923 31.3014 90.4018 31.3014 93.2364V96.329C31.3014 96.3607 31.3269 96.3862 31.3586 96.3862H54.4807C54.5123 96.3862 54.5378 96.3607 54.5378 96.329V95.7479C54.5378 95.3021 54.899 94.9409 55.3449 94.9409C55.7907 94.9409 56.1519 95.3021 56.1519 95.7479V96.329C56.1516 97.2503 55.402 97.9999 54.4804 97.9999V97.9999Z" fill="black"/>
<path d="M43.9123 67.9779H41.9886C41.5428 67.9779 41.1816 67.6166 41.1816 67.1708C41.1816 66.725 41.5428 66.3638 41.9886 66.3638H43.9123C44.3581 66.3638 44.7193 66.725 44.7193 67.1708C44.7193 67.6166 44.3581 67.9779 43.9123 67.9779Z" fill="black"/>
<path d="M80.647 61.6831H73.5499C73.6031 61.8322 73.6374 61.9901 73.6374 62.1576V64.4632C73.6374 65.2467 73.0024 65.8817 72.2189 65.8817H66.2394C65.4559 65.8817 64.8209 65.2467 64.8209 64.4632V62.1576C64.8209 61.9904 64.8551 61.8322 64.9084 61.6831H57.8112C56.2788 61.6831 55.0253 62.9369 55.0253 64.469V94.4068C55.0253 95.9392 56.2792 97.1927 57.8112 97.1927H80.647C82.1794 97.1927 83.4329 95.9388 83.4329 94.4068V64.469C83.4329 62.9369 82.1794 61.6831 80.647 61.6831V61.6831Z" fill="#5290DB"/>
<path d="M80.2047 88.6191H75.0919C74.9635 88.6191 74.8592 88.7234 74.8592 88.8519V93.9647L80.2047 88.6191Z" fill="#DAE6F1"/>
<path d="M72.2185 60.7388H66.2393C65.4558 60.7388 64.8209 61.3738 64.8209 62.1572V64.4628C64.8209 65.2463 65.4558 65.8813 66.2393 65.8813H72.2185C73.002 65.8813 73.637 65.2463 73.637 64.4628V62.1572C73.637 61.3741 73.002 60.7388 72.2185 60.7388Z" fill="#FCDD86"/>
<path d="M80.2047 88.6191V65.1439C80.2047 65.0154 80.1005 64.9111 79.972 64.9111H73.5579C73.3697 65.4735 72.8445 65.8815 72.2186 65.8815H66.2393C65.6134 65.8815 65.0882 65.4735 64.9 64.9111H58.4859C58.3574 64.9111 58.2532 65.0154 58.2532 65.1439V93.7316C58.2532 93.8601 58.3574 93.9644 58.4859 93.9644H74.8592L80.2047 88.6191Z" fill="#EDF4FC"/>
<path d="M80.647 98H57.8112C55.8298 98 54.218 96.3881 54.218 94.407V64.4689C54.218 62.4878 55.8298 60.876 57.8112 60.876H64.7689C65.2147 60.876 65.576 61.2372 65.576 61.683C65.576 62.1288 65.2147 62.4901 64.7689 62.4901H57.8112C56.7198 62.4901 55.832 63.3778 55.832 64.4689V94.4067C55.832 95.4978 56.7198 96.3855 57.8112 96.3855H80.647C81.7381 96.3855 82.6259 95.4978 82.6259 94.4067V64.4689C82.6259 63.3778 81.7381 62.4901 80.647 62.4901H73.7271C73.2813 62.4901 72.92 62.1288 72.92 61.683C72.92 61.2372 73.2813 60.876 73.7271 60.876H80.647C82.6281 60.876 84.2399 62.4878 84.2399 64.4689V94.4067C84.2399 96.3881 82.6281 98 80.647 98Z" fill="black"/>
<path d="M72.2188 66.6884H66.2393C65.0123 66.6884 64.0138 65.6899 64.0138 64.4629V62.157C64.0138 60.9296 65.0123 59.9312 66.2393 59.9312H72.2185C73.4455 59.9312 74.444 60.9296 74.444 62.157V64.4629C74.444 65.6902 73.4455 66.6884 72.2188 66.6884ZM66.2393 61.5456C65.9023 61.5456 65.6279 61.82 65.6279 62.1573V64.4632C65.6279 64.8002 65.9023 65.0746 66.2393 65.0746H72.2185C72.5555 65.0746 72.8299 64.8002 72.8299 64.4632V62.1573C72.8299 61.8203 72.5555 61.5456 72.2185 61.5456H66.2393Z" fill="black"/>
<path d="M70.873 61.3501C70.4272 61.3501 70.066 60.9889 70.066 60.5431V59.3502C70.066 58.8889 69.6905 58.5135 69.2289 58.5135C68.7676 58.5135 68.3922 58.8889 68.3922 59.3502V60.5124C68.3922 60.9582 68.0309 61.3194 67.5851 61.3194C67.1393 61.3194 66.7781 60.9582 66.7781 60.5124V59.3502C66.7781 57.9986 67.8773 56.8994 69.2289 56.8994C70.5805 56.8994 71.6801 57.9989 71.6801 59.3502V60.5431C71.6801 60.9889 71.3185 61.3501 70.873 61.3501Z" fill="black"/>
<path d="M63.741 70.2376H62.1269C61.6811 70.2376 61.3199 69.8764 61.3199 69.4306C61.3199 68.9848 61.6811 68.6235 62.1269 68.6235H63.741C64.1868 68.6235 64.5481 68.9848 64.5481 69.4306C64.5481 69.8764 64.1868 70.2376 63.741 70.2376Z" fill="black"/>
<path d="M76.0081 75.4027H66.6464C66.2006 75.4027 65.8394 75.0414 65.8394 74.5956C65.8394 74.1498 66.2006 73.7886 66.6464 73.7886H76.0081C76.4539 73.7886 76.8151 74.1498 76.8151 74.5956C76.8151 75.0414 76.4539 75.4027 76.0081 75.4027Z" fill="black"/>
<path d="M63.7411 75.4027H62.1809C61.7351 75.4027 61.3738 75.0414 61.3738 74.5956C61.3738 74.1498 61.7351 73.7886 62.1809 73.7886H63.7411C64.1869 73.7886 64.5481 74.1498 64.5481 74.5956C64.5481 75.0414 64.1869 75.4027 63.7411 75.4027Z" fill="black"/>
<path d="M76.0081 78.9534H66.6464C66.2006 78.9534 65.8394 78.5922 65.8394 78.1464C65.8394 77.7006 66.2006 77.3394 66.6464 77.3394H76.0081C76.4539 77.3394 76.8151 77.7006 76.8151 78.1464C76.8151 78.5922 76.4539 78.9534 76.0081 78.9534Z" fill="black"/>
<path d="M63.7411 78.9534H62.1809C61.7351 78.9534 61.3738 78.5922 61.3738 78.1464C61.3738 77.7006 61.7351 77.3394 62.1809 77.3394H63.7411C64.1869 77.3394 64.5481 77.7006 64.5481 78.1464C64.5481 78.5922 64.1869 78.9534 63.7411 78.9534Z" fill="black"/>
<path d="M76.0081 82.5047H66.6464C66.2006 82.5047 65.8394 82.1435 65.8394 81.6977C65.8394 81.2519 66.2006 80.8906 66.6464 80.8906H76.0081C76.4539 80.8906 76.8151 81.2519 76.8151 81.6977C76.8151 82.1435 76.4539 82.5047 76.0081 82.5047Z" fill="black"/>
<path d="M63.7411 82.5047H62.1809C61.7351 82.5047 61.3738 82.1435 61.3738 81.6977C61.3738 81.2519 61.7351 80.8906 62.1809 80.8906H63.7411C64.1869 80.8906 64.5481 81.2519 64.5481 81.6977C64.5481 82.1435 64.1869 82.5047 63.7411 82.5047Z" fill="black"/>
<path d="M76.0081 86.0555H66.6464C66.2006 86.0555 65.8394 85.6943 65.8394 85.2484C65.8394 84.8026 66.2006 84.4414 66.6464 84.4414H76.0081C76.4539 84.4414 76.8151 84.8026 76.8151 85.2484C76.8151 85.6943 76.4539 86.0555 76.0081 86.0555Z" fill="black"/>
<path d="M63.7411 86.0555H62.1809C61.7351 86.0555 61.3738 85.6943 61.3738 85.2484C61.3738 84.8026 61.7351 84.4414 62.1809 84.4414H63.7411C64.1869 84.4414 64.5481 84.8026 64.5481 85.2484C64.5481 85.6943 64.1869 86.0555 63.7411 86.0555Z" fill="black"/>
<path d="M74.8592 94.7716H58.4859C57.9126 94.7716 57.4458 94.3052 57.4458 93.7318V65.1438C57.4458 64.5705 57.9123 64.104 58.4859 64.104H64.6743C65.1201 64.104 65.4814 64.4652 65.4814 64.911C65.4814 65.3569 65.1201 65.7181 64.6743 65.7181H59.0599V93.1575H74.5248L79.3974 88.2846V65.7181H73.8481C73.4023 65.7181 73.0411 65.3569 73.0411 64.911C73.0411 64.4652 73.4023 64.104 73.8481 64.104H79.972C80.5453 64.104 81.0118 64.5705 81.0118 65.1438V88.6191C81.0118 88.8331 80.9266 89.0384 80.7755 89.1898L75.4299 94.5353C75.2785 94.6867 75.0732 94.7716 74.8592 94.7716V94.7716Z" fill="black"/>
<path d="M74.8592 94.5902C74.4134 94.5902 74.0522 94.229 74.0522 93.7832V88.8518C74.0522 88.2785 74.5187 87.812 75.0923 87.812H80.0556C80.5014 87.812 80.8627 88.1732 80.8627 88.6191C80.8627 89.0649 80.5014 89.4261 80.0556 89.4261H75.6663V93.7832C75.6663 94.229 75.305 94.5902 74.8592 94.5902Z" fill="black"/>
<path d="M46.9558 84.7642C46.9119 84.7642 46.868 84.7606 46.8235 84.7532C46.3838 84.6806 46.0862 84.2651 46.1585 83.8254L48.6484 68.7476C48.721 68.3076 49.1384 68.0103 49.5761 68.0826C50.0158 68.1552 50.3134 68.5707 50.2411 69.0104L47.7512 84.0882C47.6857 84.484 47.3435 84.7642 46.9558 84.7642Z" fill="black"/>
<path d="M38.8835 84.7643C38.4955 84.7643 38.1533 84.4837 38.0881 84.0886L35.5979 69.0095C35.5253 68.5698 35.8229 68.1543 36.2629 68.0817C36.7029 68.0091 37.118 68.3067 37.1907 68.7467L39.6809 83.8258C39.7535 84.2655 39.4559 84.681 39.0159 84.7536C38.9713 84.7607 38.9271 84.7643 38.8835 84.7643Z" fill="black"/>
<path d="M57.9684 18.2158C56.5158 17.9576 55.3859 16.8277 55.0954 15.4073C55.0954 15.3427 54.9662 15.3427 54.9662 15.4073C54.6757 16.8277 53.5458 17.9576 52.0932 18.2158C52.0286 18.2158 52.0286 18.3127 52.0932 18.3449C53.5458 18.6032 54.6757 19.7331 54.9662 21.1535C54.9662 21.218 55.0954 21.218 55.0954 21.1535C55.3859 19.7331 56.5158 18.6032 57.9684 18.3449C58.033 18.3127 58.033 18.2158 57.9684 18.2158Z" fill="#F5C84C"/>
<path d="M77.6813 55.0447C76.8623 54.8991 76.225 54.2619 76.0611 53.461C76.0611 53.4245 75.9881 53.4245 75.9881 53.461C75.8244 54.2619 75.1872 54.8991 74.3679 55.0447C74.3314 55.0447 74.3314 55.0992 74.3679 55.1176C75.1869 55.2632 75.8241 55.9005 75.9881 56.7014C75.9881 56.7379 76.0611 56.7379 76.0611 56.7014C76.2247 55.9005 76.862 55.2632 77.6813 55.1176C77.7178 55.0992 77.7178 55.0447 77.6813 55.0447V55.0447Z" fill="#70D6F9"/>
<path d="M17.584 60.7145H16.8415V59.972C16.8415 59.4232 16.3896 58.939 15.8085 58.939C15.2274 58.939 14.7755 59.3909 14.7755 59.972V60.7145H14.033C13.4842 60.7145 13 61.1664 13 61.7475C13 62.3285 13.4519 62.7805 14.033 62.7805H14.7755V63.523C14.7755 64.0718 15.2274 64.556 15.8085 64.556C16.3896 64.556 16.8415 64.104 16.8415 63.523V62.7805H17.584C18.1328 62.7805 18.617 62.3285 18.617 61.7475C18.617 61.1664 18.1651 60.7145 17.584 60.7145V60.7145Z" fill="#70D6F9"/>
<path d="M38.7931 9.7931H37.2069V8.2069C37.2069 7.03448 36.2414 6 35 6C33.7586 6 32.7931 6.96552 32.7931 8.2069V9.7931H31.2069C30.0345 9.7931 29 10.7586 29 12C29 13.2414 29.9655 14.2069 31.2069 14.2069H32.7931V15.7931C32.7931 16.9655 33.7586 18 35 18C36.2414 18 37.2069 17.0345 37.2069 15.7931V14.2069H38.7931C39.9655 14.2069 41 13.2414 41 12C41 10.7586 40.0345 9.7931 38.7931 9.7931V9.7931Z" fill="#70D6F9"/>
<path d="M62.1651 26.9154C62.7713 26.9154 63.2627 26.424 63.2627 25.8178C63.2627 25.2116 62.7713 24.7202 62.1651 24.7202C61.5589 24.7202 61.0675 25.2116 61.0675 25.8178C61.0675 26.424 61.5589 26.9154 62.1651 26.9154Z" fill="#FC657E"/>
<path d="M15.6794 71.787C16.2855 71.787 16.7769 71.2955 16.7769 70.6894C16.7769 70.0832 16.2855 69.5918 15.6794 69.5918C15.0732 69.5918 14.5818 70.0832 14.5818 70.6894C14.5818 71.2955 15.0732 71.787 15.6794 71.787Z" fill="#B2E26D"/>
<path d="M47.7674 98H38.0713C37.0957 98 36.3019 97.2062 36.3019 96.2303V84.9201C36.3019 83.9442 37.0957 83.1504 38.0713 83.1504H47.7674C48.7433 83.1504 49.5371 83.9442 49.5371 84.9201V96.2303C49.5375 97.2062 48.7436 98 47.7674 98V98ZM38.0713 84.7645C37.9854 84.7645 37.916 84.8342 37.916 84.9201V96.2303C37.916 96.3162 37.9858 96.3859 38.0713 96.3859H47.7674C47.8533 96.3859 47.923 96.3162 47.923 96.2303V84.9201C47.923 84.8342 47.853 84.7645 47.7674 84.7645H38.0713Z" fill="black"/>
<path d="M48.4386 88.3153H37.3417C36.8959 88.3153 36.5347 87.954 36.5347 87.5082C36.5347 87.0624 36.8959 86.7012 37.3417 86.7012H48.4386C48.8844 86.7012 49.2456 87.0624 49.2456 87.5082C49.2456 87.954 48.884 88.3153 48.4386 88.3153V88.3153Z" fill="black"/>
</svg>
  );
};

export default Psychiatrist;
