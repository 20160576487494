import React, { createRef } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { Page } from './types'
import ScrollToTop from './ScrollToTop'
import Footer from 'components/Footer/Footer'
import Page404 from 'containers/Page404/Page404'
import PageArchive from 'containers/PageArchive/PageArchive'
import PageAuthor from 'containers/PageAuthor/PageAuthor'
import PageSearch from 'containers/PageSearch/PageSearch'
import PageSingle from 'containers/PageSingle/PageSingle'
import PageSingleHasSidebar from 'containers/PageSingle/PageSingleHasSidebar'
import PageSingleTemplate2 from 'containers/PageSingle/PageSingleTemp2'
import PageSingleTemp2Sidebar from 'containers/PageSingle/PageSingleTemp2Sidebar'
import PageSingleTemplate3 from 'containers/PageSingle/PageSingleTemp3'
import PageSingleTemp3Sidebar from 'containers/PageSingle/PageSingleTemp3Sidebar'
import PageAbout from 'containers/PageAbout/PageAbout'
import PageContact from 'containers/PageContact/PageContact'
import PageLogin from 'containers/PageLogin/PageLogin'
import PageSignUp from 'containers/PageSignUp/PageSignUp'
import PageForgotPass from 'containers/PageForgotPass/PageForgotPass'
import PageDashboard from 'containers/PageDashboard/PageDashboard'
import GroupTherapy from 'containers/GroupTherapy/GroupTherapy'
import Educational from 'containers/Educational/Educational'
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer'
import PageHome from 'containers/PageHome/PageHome'
import PageHomeDemo2 from 'containers/PageHome/PageHomeDemo2'
import PageHomeDemo3 from 'containers/PageHome/PageHomeDemo3'
import PageAuthorV2 from 'containers/PageAuthor/PageAuthorV2'
import PageHomeDemo4 from 'containers/PageHome/PageHomeDemo4'
import PageSearchV2 from 'containers/PageSearch/PageSearchV2'
import MediaRunningContainer from 'containers/MediaRunningContainer/MediaRunningContainer'
import PageSingleGallery from 'containers/PageSingleGallery/PageSingleGallery'
import PageSingleAudio from 'containers/PageSingleAudio/PageSingleAudio'
import PageSingleVideo from 'containers/PageSingleVideo/PageSingleVideo'
import PageArchiveVideo from 'containers/PageArchive/PageArchiveVideo'
import PageArchiveAudio from 'containers/PageArchive/PageArchiveAudio'
import ModalCourse from 'components/ModalCourse/ModalCourse'
import CourseCard from 'components/CourseCard/CourseCard'
import ServiceInner from 'containers/ServiceInner'
import PageCourses from 'containers/PageCourses'
import PagePost from 'containers/PagePost/PagePost'
import TeamInner from 'containers/TeamInner'
import CardUser from 'components/CardUser/CardUser'
import { Toast } from 'containers/Toast'
// @ts-ignore
import MessengerCustomerChat from 'react-messenger-customer-chat'
import Header from 'components/Header/Header'
import { saveToastRef } from 'utils/toast'
import Quizz from 'containers/Quizz/Quizz'
import QuizzItemTest from 'containers/Quizz/QuizItemTest'
import QuizzV2 from 'containers/QuizzV2/QuizzV2'
import QuizzV3 from 'containers/QuizzV3/QuizzV3'
import ButtonQuizz from 'components/Button/ButtonQuizz'
import ButtonQuizz2 from 'components/Button/ButtonQuizz2'
import Training from 'containers/Training/Training'
import AppointmentModal from 'containers/PageDashboard/AppointmentModal'
import ResumeQuizz from 'containers/Quizz/ResumeQuizz'
import PhoneValidation from 'components/PhoneValidation/PhoneValidation'
import Loader from 'components/Loader/Loader'
// import CancelAppointmentModal from 'containers/PageDashboard/CancelAppointmentModal'

export const pages: Page[] = [
	{ path: '/', exact: true, component: PageHomeDemo3 },
	{ path: '/#', exact: true, component: PageHomeDemo3 },
	{ path: '/post/:slug', component: PagePost },
	{ path: '/article/:slug', component: PageArchive },
	{ path: '/grouptherapy/:slug/:slag', component: ServiceInner },
	// { path: "/grouptherapy/:slug", component: GroupTherapy},
	{ path: '/grouptherapy/:slug', component: ServiceInner },
	{ path: '/grouptherapy', component: GroupTherapy },
	{ path: '/educational/:slug', component: ServiceInner },
	{ path: '/educational', component: Educational },

	{ path: '/archive-video/:slug', component: PageArchiveVideo },
	{ path: '/archive-audio/:slug', component: PageArchiveAudio },
	//
	{ path: '/author/:slug', component: PageAuthor },
	{ path: '/team/:slug', component: TeamInner },
	//
	{ path: '/single/:slug', component: PageSingleTemp3Sidebar },
	{
		path: '/single-sidebar/:slug',
		component: PageSingleTemplate3
	},
	{
		path: '/single-template-2/:slug',
		component: PageSingleTemplate2
	},
	{
		path: '/single-2-sidebar/:slug',
		component: PageSingleTemp2Sidebar
	},
	{
		path: '/single-template-3/:slug',
		component: PageSingle
	},
	{
		path: '/single-3-sidebar/:slug',
		component: PageSingleHasSidebar
	},
	{
		path: '/single-gallery/:slug',
		component: PageSingleGallery
	},
	{
		path: '/single-audio/:slug',
		component: PageSingleAudio
	},
	{
		path: '/single-video/:slug',
		component: PageSingleVideo
	},

	{ path: '/search', component: PageSearch },
	{ path: '/search-v2', component: PageSearchV2 },
	{ path: '/about', component: PageAbout },
	{ path: '/contact', component: PageContact },
	{ path: '/page404', component: Page404 },
	{ path: '/login', component: PageLogin },
	// { path: '/signup', component: PageSignUp },
	{ path: '/forgot-pass', component: PageForgotPass },
	{ path: '/dashboard', component: PageDashboard },
	{ path: '/subscription', component: GroupTherapy },
	//
	{ path: '/home-demo-2', component: PageHomeDemo2 },
	{ path: '/home-demo-3', component: PageHomeDemo3 },
	{ path: '/home-demo-4', component: PageHomeDemo4 },
	{ path: '/quizz', component: Quizz },
	{ path: '/quizzv2', component: QuizzV2 },
	{ path: '/quizzv3', component: QuizzV3 },
	{ path: '/training', component: Training },
	{ path: '/survey/:slug', component: Quizz },
	{ path: '/quizztest', component: QuizzItemTest },
	{ path: '/resume-survay', component: ResumeQuizz }
	//
]

const Routes = () => {
	return (
		<BrowserRouter basename=''>
			<ScrollToTop />
			<HeaderContainer />
			<Toast ref={saveToastRef} />
			<Switch>
				{pages.map(({ component, path, exact }) => {
					return <Route key={path} component={component} exact={!!exact} path={path} />
				})}
				<Route component={Page404} />
			</Switch>
			<MessengerCustomerChat pageId='116101687351405' appId='592310481806103' />
			<Footer />
			{/* MEDIA */}
			<MediaRunningContainer />
		</BrowserRouter>
	)
}

export default Routes
