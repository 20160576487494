import React from "react";

const SingleContentDemo = () => {
  return (
    <>
      {/* THIS IS THE DEMP CONTENT */}
      {/* IF YOUR DATA IS JSON, YOU CAN USE render with html-react-parser (https://www.npmjs.com/package/html-react-parser) */}
      <p>
        გახტა გამოხტა კურდღელი
        თუ შენ გაქვს განცდა, რომ ხმა, რომელიც შენ თავში ისმის შენი ყველაზე ცუდი
        კრიტიკოსია, განაგრძე კითხვა! იმიტომ, რომ საკუთარ თავთან საუბრის გზა
        სასიცოცხლოდ მნიშვნელოვან როლს ასრულებს შენ კეთილდღეობაზე. ალბათ სჯობს,
        ეს ხმა მეტ სტიმულსა და საკუთარი თავის რწმენას გაძლევდეს, და არა
        დაუსრულებელ, არაფრის მომცემ კრიტიკას... ამ სტატიაში მე შემოგთავაზებ ხუთ
        გზას, რომელიც დაგეხმარება დაძლიო შენი შინაგანი კრიტიკოსი
        თვით-თანაგრძნობის გაძლიერებით.
      </p>
      <p>
        წარმოიდგინე, დაუსრულებლად გიწევს სამოგზაუროდ წასვლა. იცი, რომ ამ გზაზე
        შეგხვდება წვიმა, ქარი, ქარბუქიც კი - და გაიხსენე, რომ ამ მოგზაურობაში არ
        იქნები მარტო, გეყოლება „ვიღაც“, ვინც მუდამ შენ გვერდზე იქნება. ალბათ,
        დაინტერესდები. გაეცნობი და გაუზიარებთ ერთმანეთს პოზიტივს და მისცემთ
        ძალას, რადგან ჯერ კიდევ დიდი გზაა წინ. ასეა ჩვენი თანაცხოვრება ჩვენივე
        „მესთან“ ერთად. როგორ გინდა იმოგზაურო თუ მასში ვერაფერ პოზიტიურს ვერ
        ხედავ?
      </p>
      <p>
        და რის თქმა შეგიძლია იმ მოგზაურობაზე, რომელშიც ახლა ხარ, რომელსაც
        სიცოცხლეს ეძახიან? როგორ გრძნობ თავს ამ ურთიერთობაში, რაც შენ შინაგან
        ხმასთან გაქვს? გაქვს პოზიტიური, მხარდამჭერი ურთიერთობა თუ ის შენი
        ყველაზე ცუდი კრიტიკოსია?
      </p>
      <p>
        “შენ შეგიძლია ეძებო მთელ სამყაროში ვინმე, ვინც უფრო მეტად იმსახურებს
        შენს სიყვარულს და სითბოს შენ თავზე მეტად და ამ ადამიანს ვერსად იპოვი.
        შენ შენი თავისგან იმსახურებ სიყვარულს და სითბოს ისე როგორც სხვა არავინ
        მთელ სამყაროში.” ბუდა
      </p>
      <h3>ნაბიჯი 1: სცადე პატიება</h3>
      <figure>
        <img
          src="https://i.ibb.co/B4Jz4nB/Forgive.jpg"
          alt="Forgive"
        />
        <figcaption>
        იცოდე რომ სრულყოფილი, იდეალური ადამიანები ფიზიკურად სამყაროში
        არ არსებობენ.
        </figcaption>
      </figure>
      <ul>
        <li>
          შეწყვიტე საკუთარი თავის დასჯა შენი შეცდომების გამო. აღიარე, რომ არ ხარ
          სრულყოფილი და იყავი თავაზიანი საკუთარ თავთან, როდესაც შენ
          ნაკლოვანებებს შეხვდები. როგორც კი საკუთარი თავის დაფასაებას დაიწყებ,
          მიხვდები, რომ მეგობრების თუ კოლეგების შემოხედვა იცვლება. როგორც კი
          საკუთარ თავს სარკეში კარგად დაინახავ, მიხვდები, რომ სამყარო აუცილებლად
          გამოგიგზანის დადებით ენერგიასა და პოზიტივს და შეაჩნევ, რომ ხალხიც
          შეიცვალა შენ ირგვლივ. სცადე პატიება და მიიღე საკუთარი თავი ისეთი,
          როგორიც ხარ და იბრძოლე საკუთარი თავისთვის !
        </li>
        <li>
          გააცნობიერე ყოველი შესაძლებლობა: საკუთარი თავის დაფასებაა შესაძლებელი
          მიღებული საქმისგან თუ ურთიერთობისგან. დაიჯერე, რომ შენ არ გჭირდება
          რაიმე გზის გავლა, რომ იყო სიყვარულის ღირსი. ერთ-ერთი მარტივი გზა
          იმისთვის, რომ საკუთარ თავს შეახსენო სიყვარული საკუთარი თავისგან
          უპირობოდ შეგიძლია პატარა ფურცელზე ჩანაწერი გამოიყენო და საფულეში ან
          სამუშაო მაგიდასთან დაამაგრო.
        </li>
        <li>
          ”აზრი არ აქვს დასაჯო შენი მომავალი წარსულის შეცდომების გამო. აპატიე
          საკუთარ თავს, გაიზარდე მისგან და შემდეგ გაუშვი. ” მელანი კულურისი
        </li>
      </ul>
      <h3>ნაბიჯი 2 იყავი თვითგანვითარების კონტექსტში</h3>
      <figure>
        <img
          src="https://i.ibb.co/5vSjjk4/Create.jpg"
          alt="Create"
        />
        <figcaption>
          იცხოვრე, იშრომე, შექმენი.
        </figcaption>
      </figure>
      <p>
        Carol Dweck-ის კვლევების მთავარი ცენტრი არის ჩვენი აზროვნების გავლენა
        ჩვენ კეთილდღეობაზე. მან აღმოაჩინა, რომ ჩვენ გვაქვს ფიქსირებული ან მზარდი
        აზროვნება, რაც მნიშვნელოვან გავლენას ახდენს ჩვენს ბედნიერებაზე. როგორ
        განიხილავთ ცხოვრებისეულ გამოწვევებს გადაულახავ დაბრკოლებად თუ
        განვითარების შესაძლებლობად?
      </p>
      <p>
        გაიცანი შენი შეხედულება სამყაროს მიმართ და სცადე განვითარების
        შესაძლებლობად აქციო ყოველი გამოწვევა. ნაცვლად იმისა, რომ აირიდო
        გამოწვევები. არ დანებდე და იპოვე მათში აზრი, არ დანებდე საკუთარ თავს !
        ეს უპირობოა ! როგორც კი შეწყვეთ თვით განვითარებას მიხვდები, რომ თითქოს
        ჩამორჩი სამყაროს, რადგან თავად სამყარო ვითარდება სწრაფად. შეიმეცნე
        საკუთარი თავი, არა ფულისთვის, არა ხალხისთვის, არამედ მხოლოდ საკუთარი
        თავისთვის, რომ სულში იგრძნო კმაყოფილება და სიმშვიდე. არ დანდებდე,
        გახსოვდეს, არ დანებდე საკუთარ თავს !
      </p>
      <h3>ნაბიჯი 3: გამოხატე მადლიერება</h3>
      <p>
        მადლიერების გრძნობა ძალიან ძლიერია. (Emmons & McCullough, 2003).
        რეალურად ვიწყებთ შფოთვას, ჩვენი ორგანიზმი განიცდის სტრესს და თავს ვატანთ
        ძალას ინერვიულოს იმაზე, რაც არ გვაქვს და სამწუხაროდ, გვავიწყდება ის,
        რაზეც ვიშრომეთ და მივიღეთ, გვავიწყდება ახლანდელი დღე და იმდენად
        მივესწრაფით „არარეალურისკენ“, რომ გვავიწყდება ის, რაც უკვე გვაქვს. ეს
        გრძნობა კი ხელს გიშლის იმაში, რომ უკვე მოსული „ბედნიერი წუთები“ თუ
        მოვლენა შეაფასო, გადაახარისხო და დატკბე, თუნდაც დეტალებით. ხანდახან
        დეტალები გაბედნიერებენ, ხანდახან უბრალოდ იმის შეფასება და ამის
        მადლიერება გამოხატავს უდიდეს ბედნიერებას სულში. ეს ემოცა კი მოგცემს იმის
        უნარს უკეთ გადააფსო მომავლის მოვლენები და სწაფადვე მიიღო ისინი, რადგან
        წარსულში უკვე იყავი მადლიერი და ახლა აწმყოს დროა !
      </p>
      <p>
        შეგიძლია მადლიერების დღიური შექმნა ან გულწრფელად ღიად გამოასეირნო ხოლმე
        შენი მადლიერება. ჩვენი მადლიერებაზე ფოკუსირებით ვფლობთ უფრო ნაზ ხმას,
        ნაკლებ ყურადღებას ვაქცევთ ჩვენ ნაკლოვანებებს, ფოკუსი ჩვენი თავის გარეთაც
        გაგვაქვს და ვუშვებთ ჩვენში მთელ სამყაროს თავისი სილამაზით.
      </p>
      <h3>ნაბიჯი 4 იპოვეთ გულუხვობის სწორი დონე</h3>
      <p>
        რაჯ რაღუნათანმა (2016) გამოავლინა სამი განსხვავებული
        ურთიერთდამოკიდებულების სტილი: გამცემი, მიმღები და შემხვედრი. ცხადია,
        გამცემი ყველაზე გულუხვი ხალხია და გულუხვობა თანაგრძნობის გამოყენების
        შესანიშნავი საშუალებაა. თუმცა, გამცემი შეიძლება იყოს როგორც ყველაზე
        წარმატებული, ასევე ყველაზე წარუმატებელი ადამიანი, რადგან ისინი შეიძლება
        გადავარდნენ თავდაუზოგავი სხვებისთვის გაცემის მორევში, იმის ხარჯზე, რომ
        იგნორირება გაუკეთონ საკუთარ მოთხოვნილებებს.
      </p>
      <p>
        გულუხვობამ, რომ თქვენი კეთილდღეობის სასარგებლოდ იმუშაოს, არ შეიძლება
        იყოს თავგანწირული. ასე რომ, როდესაც გულუხვობას გადაწყვეტ, დაფიქრდი ჯერ,
        შენი საჭიროებები თუა დაკმაყოფილებული. ასევე, ისიამოვნე გულუხვობით და არ
        დაგავიწყდეს საკუთარ თავთან დაბრუნება.{" "}
        <strong>
          კარგი რამეების კეთება სხვებისთვის ჩვენ თვითონვე გვაბედნიერებს,
        </strong>{" "}
        მაგრამ მხოლოდ იმ შემთხვევაში თუ ეს არ ამცირებს ჩვენს კეთილდღეობას.
      </p>
      <h3>ნაბიჯი 5: იყავი აქ და ამჟამად</h3>
      <p>
        აღმოჩნდა, რომ ფიქრის დროს აწმყო დროში დაბრუნება და ძირითადად აწმყო
        პრობლემებზე, ემოციებზე ორიენტირება დადებითად აისახება თვითთანაგრძნობაზე,
        ვინაიდან ის თვითკრიტიკულობას ამცირებს (Kabat-Zinn, 2014). რასაც არ უნდა
        აკეთებდე ეცადე, რომ ეს კონკრეტული მომენტი შეიგრძნო და დააკვირდე, ახლა რა
        ხდება განსჯის და იარლიყების გარეშე.
      </p>
      <p>
        დაუშვი, რომ რასაც ფიქრობ ან გრძნობ ის არის მომენტი, არ მისცე მას არც
        მიკროფონი და არც დამალო სადმე კუთხეში. მიეცი ნება, რომ გამომჟღავნდეს. და
        ჰკითხე საკუთარ თავს: სად არის ჩვენი ცხოვრება? წარსულში, აწმყოში თუ
        მომავალში?
      </p>
      <p>Something a wise person once told me about typography is:</p>
      <blockquote>
        <p>
          Typography is pretty important if you don't want your stuff to look
          like trash. Make it good then it won't be bad.
        </p>
      </blockquote>
      <p>
        It's probably important that images look okay here by default as well:
      </p>
      <figure>
        <img
          src="https://i.ibb.co/5vSjjk4/Create.jpg"
          alt="Create"
        />
        <figcaption>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iure vel
          officiis ipsum placeat itaque neque dolorem modi perspiciatis dolor
          distinctio veritatis sapiente
        </figcaption>
      </figure>
      <p>
        Now I'm going to show you an example of an unordered list to make sure
        that looks good, too:
      </p>
      <ul>
        <li>So here is the first item in this list.</li>
        <li>In this example we're keeping the items short.</li>
        <li>Later, we'll use longer, more complex list items.</li>
      </ul>
      <p>And that's the end of this section.</p>
      <h2>Code should look okay by default.</h2>
      <p>
        I think most people are going to use{" "}
        <a href="https://highlightjs.org/">highlight.js</a> or{" "}
        <a href="https://prismjs.com/">Prism</a> or something if they want to
        style their code blocks but it wouldn't hurt to make them look{" "}
        <em>okay</em> out of the box, even with no syntax highlighting.
      </p>
      <p>
        What I've written here is probably long enough, but adding this final
        sentence can't hurt.
      </p>
      <pre>
        <code className="language-js">
          {`function tick() {
  const element = (
    <div>
      <h1>Hello, world!</h1>
      <h2>It is {new Date().toLocaleTimeString()}.</h2>
    </div>
  );
  ReactDOM.render(element, document.getElementById('root'));
} `}
        </code>
      </pre>
      <p>Hopefully that looks good enough to you.</p>
      <h3>We still need to think about stacked headings though.</h3>
      <h4>
        Let's make sure we don't screw that up with <code>h4</code> elements,
        either.
      </h4>
      <p>
        Phew, with any luck we have styled the headings above this text and they
        look pretty good.
      </p>
      <p>
        Let's add a closing paragraph here so things end with a decently sized
        block of text. I can't explain why I want things to end that way but I
        have to assume it's because I think things will look weird or unbalanced
        if there is a heading too close to the end of the document.
      </p>
      <p>
        What I've written here is probably long enough, but adding this final
        sentence can't hurt.
      </p>
    </>
  );
};

export default SingleContentDemo;
