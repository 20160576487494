import React from "react";

const Educational = () => {
  return (
    <svg width="98" height="98" viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M85.2305 42.5815C84.9844 42.5815 84.7436 42.6022 84.5078 42.639V51.1661C84.5078 51.4203 84.4963 51.6714 84.4795 51.9214C84.7245 51.9608 84.9744 51.9869 85.2305 51.9869C87.8279 51.9869 89.933 49.8814 89.933 47.2844C89.933 44.687 87.8275 42.5815 85.2305 42.5815Z" fill="#F9D0B4"/>
<path d="M61.9804 51.1661C61.9804 49.9993 61.9804 44.9592 61.9804 42.639C61.7446 42.6022 61.5038 42.5815 61.2576 42.5815C58.6603 42.5815 56.5552 44.687 56.5552 47.2844C56.5552 49.8818 58.6606 51.9869 61.2576 51.9869C61.5138 51.9869 61.7637 51.9608 62.0087 51.9214V51.921C61.9919 51.6714 61.9804 51.4203 61.9804 51.1661Z" fill="#F9D0B4"/>
<path d="M95.7958 74.2639C95.6874 72.3219 94.3292 70.6755 92.4427 70.2008L85.977 68.5734C84.427 68.1834 82.9237 67.6248 81.4939 66.9105C80.8642 66.5958 80.319 66.3191 80.218 66.254C79.1151 65.5435 78.4333 64.3169 78.4333 62.9851V61.1519C76.8775 61.9661 75.1128 62.4301 73.2443 62.4301C71.3754 62.4301 69.6106 61.9661 68.0553 61.1519V62.9851C68.0553 64.4119 67.2736 65.7177 66.0294 66.3975C65.977 66.4262 65.4935 66.6663 64.9154 66.9522C63.512 67.6463 62.0374 68.1899 60.5192 68.5719L54.0458 70.2012C52.1593 70.6758 50.8011 72.3223 50.6928 74.2643L49.4475 95.7386C49.4081 96.4468 49.972 97.0428 50.6813 97.0428H95.808C96.5174 97.0428 97.0812 96.4468 97.0418 95.7386L95.7958 74.2639Z" fill="#F98FA6"/>
<path d="M83.8558 41.8767C80.3615 41.696 77.2163 40.3569 74.9386 38.06C74.0133 37.1271 72.4741 37.1271 71.5492 38.06C69.2715 40.3573 66.1259 41.6964 62.632 41.8767C62.2652 41.8954 61.9804 42.2044 61.9804 42.5719V50.9375C61.9804 56.8431 66.4367 61.9181 72.3229 62.3927C78.9287 62.9256 84.5078 57.6658 84.5078 51.1664V42.5722C84.5074 42.2047 84.223 41.8958 83.8558 41.8767Z" fill="#F9D0B4"/>
<path d="M87.0252 37.3243C83.5205 26.1733 76.3041 27.4848 73.9816 28.2382C73.5 28.3944 72.9882 28.3944 72.5066 28.2382C70.1841 27.4845 62.9673 26.1733 59.463 37.3243C55.2521 50.7227 51.3202 51.901 53.338 58.7618C54.5217 62.7867 58.1967 65.9315 63.0917 67.7464C63.7103 67.5071 64.3202 67.246 64.9154 66.9517C65.4935 66.6657 65.9766 66.4257 66.0294 66.397C67.2739 65.7171 68.0553 64.4113 68.0553 62.9846V61.1513L68.056 61.1413C64.6881 59.3509 62.3583 55.8757 62.0252 51.914L62.0091 51.9209C61.7637 51.9603 61.5141 51.9864 61.258 51.9864C58.6687 51.9864 56.5682 49.8939 56.5556 47.3073C56.5468 45.5226 57.6244 43.9485 59.1993 43.1097C60.19 42.5822 61.1202 42.5049 61.9808 42.6381V42.5707C61.9808 42.2036 62.2656 41.8947 62.6323 41.8755C66.1267 41.6948 69.2718 40.3558 71.5496 38.0589C72.4614 37.1394 74.0271 37.1394 74.939 38.0589C77.2167 40.3561 80.3619 41.6952 83.8562 41.8755C84.223 41.8943 84.5078 42.2032 84.5078 42.5707V42.6381C85.3308 42.5106 86.2167 42.5757 87.1653 43.0431C88.8098 43.8539 89.9491 45.4916 89.933 47.3252C89.9104 49.9031 87.8138 51.986 85.2305 51.986C84.9744 51.986 84.7245 51.96 84.4795 51.9205C84.2103 55.9286 81.822 59.3777 78.4314 61.1517C78.4322 61.1513 78.4329 61.1509 78.4333 61.1505V62.9838C78.4333 64.316 79.1147 65.5421 80.218 66.2526C80.319 66.3177 80.8642 66.5945 81.4939 66.9092C82.1148 67.2193 82.7518 67.4945 83.3984 67.7449C88.2926 65.9299 91.9669 62.7851 93.1505 58.7606C95.168 51.901 91.2361 50.7227 87.0252 37.3243Z" fill="#FCDD86"/>
<path d="M71.8991 74.345C77.4602 74.7937 82.5187 72.4267 85.8085 68.5259C84.3183 68.1381 82.8712 67.5983 81.4935 66.91C80.8638 66.5953 80.3186 66.3186 80.2176 66.2535C79.1147 65.543 78.4329 64.3165 78.4329 62.9847V61.1514C76.8775 61.9656 75.1124 62.4296 73.2439 62.4296C71.375 62.4296 69.6102 61.9656 68.0549 61.1514V62.9847C68.0549 64.4114 67.2731 65.7172 66.029 66.397C65.9762 66.4258 65.4931 66.6658 64.915 66.9517C63.5813 67.6113 62.1825 68.13 60.7439 68.5086C63.4645 71.7706 67.3937 73.9817 71.8991 74.345Z" fill="#F9D0B4"/>
<path d="M88.9266 97.0428H95.8072C96.5166 97.0428 97.0805 96.4468 97.041 95.7386L96.2463 82.0347L88.9266 84.1976V97.0428Z" fill="#F9D0B4"/>
<path d="M57.7251 84.167L50.2461 81.957L49.4467 95.7383C49.4073 96.4465 49.9712 97.0425 50.6805 97.0425H57.7251V84.167Z" fill="#F9D0B4"/>
<path d="M36.7424 42.5815C36.4962 42.5815 36.2554 42.6022 36.0196 42.639V51.1661C36.0196 51.4203 36.0081 51.6714 35.9913 51.9214C36.2363 51.9608 36.4863 51.9869 36.7424 51.9869C39.3397 51.9869 41.4448 49.8814 41.4448 47.2844C41.4452 44.687 39.3397 42.5815 36.7424 42.5815Z" fill="#F9D0B4"/>
<path d="M13.4926 51.1661C13.4926 49.9993 13.4926 44.9592 13.4926 42.639C13.2568 42.6022 13.016 42.5815 12.7699 42.5815C10.1725 42.5815 8.06741 44.687 8.06741 47.2844C8.06741 49.8818 10.1729 51.9869 12.7699 51.9869C13.026 51.9869 13.276 51.9608 13.521 51.9214V51.921C13.5041 51.6714 13.4926 51.4203 13.4926 51.1661Z" fill="#F9D0B4"/>
<path d="M47.308 74.2639C47.1996 72.3219 45.8414 70.6755 43.9549 70.2008L37.4892 68.5734C35.9392 68.1834 34.4359 67.6248 33.0061 66.9105C32.3764 66.5958 31.8312 66.3191 31.7302 66.254C30.6273 65.5435 29.9455 64.3169 29.9455 62.9851V61.1519C28.3898 61.9661 26.625 62.4301 24.7565 62.4301C22.8876 62.4301 21.1228 61.9661 19.5675 61.1519V62.9851C19.5675 64.4119 18.7858 65.7177 17.5416 66.3975C17.4892 66.4262 17.0057 66.6663 16.4276 66.9522C15.0242 67.6463 13.5496 68.1899 12.0314 68.5719L5.55806 70.2012C3.67156 70.6758 2.31334 72.3223 2.205 74.2643L0.959713 95.7386C0.920283 96.4468 1.48417 97.0428 2.19352 97.0428H47.3195C48.0288 97.0428 48.5927 96.4468 48.5533 95.7386L47.308 74.2639Z" fill="#F5C84C"/>
<path d="M34.4099 41.1227C34.2571 41.1422 34.1013 41.1525 33.9432 41.1525C32.7775 41.1525 31.742 40.6036 31.0775 39.7503C30.6506 39.2021 29.9237 39.0279 29.2886 39.31C28.8392 39.5099 28.3419 39.6209 27.8182 39.6209C26.9864 39.6209 26.2211 39.3411 25.609 38.8706C25.1083 38.4858 24.4028 38.4858 23.902 38.8706C23.2903 39.3411 22.5247 39.6209 21.6928 39.6209C21.1691 39.6209 20.6719 39.5095 20.2224 39.31C19.5874 39.0279 18.8608 39.2021 18.4336 39.7503C17.769 40.6036 16.7331 41.1525 15.5678 41.1525C15.4097 41.1525 15.2539 41.1422 15.1012 41.1227C14.2452 41.0124 13.4915 41.6888 13.4915 42.5521V50.9376C13.4915 56.8432 17.9478 61.9182 23.8339 62.3929C30.4397 62.9257 36.0188 57.6659 36.0188 51.1665V42.5521C36.0196 41.6888 35.2658 41.0124 34.4099 41.1227Z" fill="#F9D0B4"/>
<path d="M23.4879 25.9404C15.3849 26.5938 9.25067 33.5802 9.25067 41.7096V44.1722C10.1124 43.1987 11.3676 42.5816 12.7699 42.5816C13.016 42.5816 13.2568 42.6023 13.4926 42.6391V42.5518C13.4926 41.6885 14.2464 41.0121 15.1023 41.1223C15.2551 41.1419 15.4109 41.1522 15.569 41.1522C16.7347 41.1522 17.7702 40.6033 18.4347 39.75C18.8616 39.2018 19.5885 39.0276 20.2236 39.3097C20.673 39.5096 21.1703 39.6206 21.694 39.6206C22.5258 39.6206 23.2911 39.3407 23.9032 38.8703C24.4039 38.4855 25.1094 38.4855 25.6102 38.8703C26.2219 39.3407 26.9875 39.6206 27.8194 39.6206C28.3431 39.6206 28.8403 39.5092 29.2898 39.3097C29.9248 39.0276 30.6514 39.2018 31.0786 39.75C31.7432 40.6033 32.7791 41.1522 33.9444 41.1522C34.1025 41.1522 34.2583 41.1419 34.411 41.1223C35.267 41.0121 36.0208 41.6885 36.0208 42.5518V42.6391C36.2566 42.6023 36.4974 42.5816 36.7435 42.5816C38.1457 42.5816 39.401 43.1987 40.2627 44.1722V41.3945C40.2615 32.4474 32.5816 25.2069 23.4879 25.9404Z" fill="#754E34"/>
<path d="M17.5408 66.3972C17.488 66.4259 17.0049 66.6659 16.4269 66.9519C15.6191 67.3515 14.7857 67.6949 13.9363 67.9924C15.6547 71.8836 19.3282 74.7478 23.7853 75.1073C29.0386 75.5311 33.671 72.4762 35.6303 68.0092C34.7341 67.6972 33.8555 67.3351 33.0057 66.9101C32.376 66.5955 31.8309 66.3187 31.7298 66.2536C30.6269 65.5431 29.9451 64.3166 29.9451 62.9848C29.9451 62.9323 29.9451 61.1125 29.9451 61.1515C28.3897 61.9657 26.6246 62.4297 24.7561 62.4297C22.8872 62.4297 21.1224 61.9657 19.5671 61.1515C19.5671 61.2679 19.5671 63.0709 19.5671 62.9848C19.5671 64.4119 18.7854 65.7173 17.5408 66.3972Z" fill="#FCDD86"/>
<path d="M23.9878 71.5003C27.7566 71.8043 31.1234 69.847 32.8756 66.8449C32.3029 66.5582 31.8236 66.314 31.7298 66.2535C30.6269 65.543 29.9451 64.3165 29.9451 62.9847V61.1514C28.3898 61.9656 26.6246 62.4296 24.7561 62.4296C22.8872 62.4296 21.1224 61.9656 19.5671 61.1514V62.9847C19.5671 64.4114 18.7854 65.7172 17.5412 66.397C17.4964 66.4215 17.1397 66.5992 16.6791 66.8269C18.1798 69.4105 20.8372 71.2461 23.9878 71.5003Z" fill="#F9D0B4"/>
<path d="M40.4388 97.043H47.3195C48.0288 97.043 48.5927 96.4469 48.5533 95.7387L47.8964 84.4102H40.4388V97.043Z" fill="#F9D0B4"/>
<path d="M9.07343 97.043H2.19275C1.4834 97.043 0.91952 96.4469 0.95895 95.7387L1.61586 84.4102H9.07343V97.043Z" fill="#F9D0B4"/>
<path d="M1.41605 87.8555H9.23729V84.4102H1.61587L1.41605 87.8555Z" fill="#FCDD86"/>
<path d="M47.8964 84.4102H40.4388V87.8555H48.0962L47.8964 84.4102Z" fill="#FCDD86"/>
<path d="M49 30.0508C56.294 30.0508 62.207 24.1378 62.207 16.8438C62.207 9.54971 56.294 3.63672 49 3.63672C41.706 3.63672 35.793 9.54971 35.793 16.8438C35.793 24.1378 41.706 30.0508 49 30.0508Z" fill="#FCA265"/>
<path d="M48.5607 8.81611C45.8965 8.8914 43.6753 11.0012 43.474 13.6588C43.3486 15.3126 43.9897 16.8198 45.0787 17.863C45.7106 18.4682 46.1457 19.2497 46.3579 20.0985L46.3581 20.0988C46.4421 20.4354 46.7446 20.6715 47.0915 20.6715H50.3342C50.6811 20.6715 50.9835 20.4354 51.0676 20.0988C51.2789 19.2539 51.7077 18.4723 52.3381 17.8713C53.3417 16.9143 53.9673 15.5645 53.9673 14.0683C53.9673 11.1158 51.5322 8.7322 48.5607 8.81611Z" fill="#F5C84C"/>
<path d="M50.3342 20.6714H47.0915C47.0002 20.6714 46.9125 20.6543 46.8307 20.624V22.6924C46.8307 23.0836 47.1478 23.4007 47.539 23.4007H49.8865C50.2777 23.4007 50.5949 23.0836 50.5949 22.6924V20.624C50.5131 20.6543 50.4255 20.6714 50.3342 20.6714Z" fill="#EDF4FC"/>
<path d="M47.5953 23.4009V23.8518C47.5953 24.469 48.0957 24.9694 48.7128 24.9694C49.33 24.9694 49.8304 24.469 49.8304 23.8518V23.4009H47.5953Z" fill="#5290DB"/>
<path d="M47.9286 20.8481C47.712 20.8481 47.5365 20.6726 47.5365 20.456V16.0488L46.7022 15.1641C46.5537 15.0066 46.5609 14.7584 46.7185 14.6098C46.8761 14.4612 47.1241 14.4684 47.2728 14.6261L48.2139 15.6241C48.2826 15.6969 48.3207 15.793 48.3207 15.8931V20.456C48.3207 20.6726 48.1452 20.8481 47.9286 20.8481Z" fill="black"/>
<path d="M49.4971 20.8481C49.2805 20.8481 49.1049 20.6726 49.1049 20.456V15.8931C49.1049 15.7931 49.1432 15.6969 49.2118 15.6242L50.1529 14.6261C50.3014 14.4685 50.5495 14.4611 50.7072 14.6098C50.8646 14.7584 50.872 15.0065 50.7235 15.1641L49.8892 16.0489V20.456C49.8892 20.6726 49.7137 20.8481 49.4971 20.8481Z" fill="black"/>
<path d="M50.8303 22.3814H46.5954C46.3788 22.3814 46.2033 22.2059 46.2033 21.9893C46.2033 21.7727 46.3788 21.5972 46.5954 21.5972H50.8303C51.0469 21.5972 51.2224 21.7727 51.2224 21.9893C51.2224 22.2059 51.0468 22.3814 50.8303 22.3814Z" fill="black"/>
<path d="M50.3342 21.0636H47.0915C46.5635 21.0636 46.1056 20.706 45.9776 20.1939C45.7782 19.3965 45.3735 18.6885 44.8073 18.1461C43.5826 16.973 42.9542 15.3266 43.0829 13.6292C43.298 10.791 45.6993 8.50477 48.5497 8.42415C50.094 8.38118 51.5457 8.94724 52.6485 10.0193C53.7517 11.0918 54.3594 12.5298 54.3594 14.0683C54.3594 15.6274 53.7376 17.0787 52.6086 18.155C52.0496 18.6881 51.6483 19.3932 51.4483 20.1939C51.3201 20.7058 50.8621 21.0636 50.3342 21.0636ZM48.5718 9.20808C46.1178 9.27741 44.0502 11.2454 43.865 13.6883C43.7541 15.1511 44.2954 16.5694 45.35 17.5797C46.0232 18.2243 46.5033 19.0625 46.7384 20.0033C46.7791 20.166 46.9243 20.2794 47.0915 20.2794H50.3342C50.5015 20.2794 50.6468 20.166 50.6871 20.0038C50.9233 19.0589 51.4006 18.2232 52.0674 17.5875C53.0397 16.6607 53.575 15.411 53.575 14.0683C53.575 12.7436 53.0517 11.5053 52.1015 10.5816C51.152 9.65839 49.8977 9.17059 48.5718 9.20808ZM48.5607 8.81612H48.5623H48.5607Z" fill="black"/>
<path d="M49.8865 23.7928H47.539C46.9321 23.7928 46.4385 23.2992 46.4385 22.6922V20.7408C46.4385 20.5242 46.6141 20.3487 46.8307 20.3487C47.0473 20.3487 47.2228 20.5242 47.2228 20.7408V22.6922C47.2228 22.8666 47.3646 23.0085 47.539 23.0085H49.8865C50.0609 23.0085 50.2027 22.8666 50.2027 22.6922V20.708C50.2027 20.4914 50.3783 20.3159 50.5949 20.3159C50.8115 20.3159 50.987 20.4914 50.987 20.708V22.6922C50.9871 23.2992 50.4934 23.7928 49.8865 23.7928Z" fill="black"/>
<path d="M48.7128 25.3612C47.8805 25.3612 47.2032 24.684 47.2032 23.8516V23.5313C47.2032 23.3147 47.3787 23.1392 47.5953 23.1392C47.8119 23.1392 47.9874 23.3147 47.9874 23.5313V23.8516C47.9874 24.2515 48.3129 24.577 48.7128 24.577C49.1128 24.577 49.4383 24.2515 49.4383 23.8516V23.5379C49.4383 23.3213 49.6138 23.1457 49.8304 23.1457C50.047 23.1457 50.2225 23.3213 50.2225 23.5379V23.8516C50.2225 24.684 49.5452 25.3612 48.7128 25.3612Z" fill="black"/>
<path d="M73.9908 10.6613C72.2681 10.3551 70.9283 9.01523 70.5837 7.33086C70.5837 7.2543 70.4306 7.2543 70.4306 7.33086C70.0861 9.01523 68.7462 10.3551 67.0236 10.6613C66.947 10.6613 66.947 10.7762 67.0236 10.8145C68.7462 11.1207 70.0861 12.4605 70.4306 14.1449C70.4306 14.2215 70.5837 14.2215 70.5837 14.1449C70.9283 12.4605 72.2681 11.1207 73.9908 10.8145C74.0673 10.7762 74.0673 10.6613 73.9908 10.6613Z" fill="#F5C84C"/>
<path d="M49.7189 61.2067C48.3159 60.9571 47.2245 59.8661 46.9439 58.4941C46.9439 58.4317 46.8191 58.4317 46.8191 58.4941C46.5385 59.8661 45.4471 60.9575 44.0441 61.2067C43.9817 61.2067 43.9817 61.3001 44.0441 61.3315C45.4471 61.5811 46.5385 62.6721 46.8191 64.0441C46.8191 64.1065 46.9439 64.1065 46.9439 64.0441C47.2245 62.6721 48.3159 61.5807 49.7189 61.3315C49.7813 61.3001 49.7813 61.2067 49.7189 61.2067Z" fill="#FC657E"/>
<path d="M7.72591 28.1367H6.84545V27.2562C6.84545 26.6055 6.30951 26.0312 5.62045 26.0312C4.93138 26.0312 4.39545 26.5672 4.39545 27.2562V28.1367H3.51498C2.8642 28.1367 2.28998 28.6727 2.28998 29.3617C2.28998 30.0508 2.82592 30.5867 3.51498 30.5867H4.39545V31.4672C4.39545 32.118 4.93138 32.6922 5.62045 32.6922C6.30951 32.6922 6.84545 32.1562 6.84545 31.4672V30.5867H7.72591C8.3767 30.5867 8.95092 30.0508 8.95092 29.3617C8.95092 28.6727 8.41498 28.1367 7.72591 28.1367Z" fill="#70D6F9"/>
<path d="M14.272 22.8922C14.9909 22.8922 15.5736 22.3095 15.5736 21.5906C15.5736 20.8718 14.9909 20.2891 14.272 20.2891C13.5532 20.2891 12.9705 20.8718 12.9705 21.5906C12.9705 22.3095 13.5532 22.8922 14.272 22.8922Z" fill="#F5C84C"/>
<path d="M62.1236 2.60313C62.8424 2.60313 63.4251 2.0204 63.4251 1.30156C63.4251 0.582729 62.8424 0 62.1236 0C61.4047 0 60.822 0.582729 60.822 1.30156C60.822 2.0204 61.4047 2.60313 62.1236 2.60313Z" fill="#70D6F9"/>
<path d="M73.5605 56.1031C72.0564 56.1031 70.6415 55.4864 69.7752 54.4528C69.4357 54.0478 69.4889 53.4441 69.8943 53.1046C70.2985 52.765 70.9026 52.8178 71.2422 53.2232C71.749 53.8281 72.6157 54.1887 73.5605 54.1887C74.5053 54.1887 75.3716 53.8277 75.8788 53.2232C76.2183 52.8178 76.8228 52.7654 77.2267 53.1046C77.6321 53.4441 77.6849 54.0478 77.3457 54.4528C76.4794 55.4864 75.0645 56.1031 73.5605 56.1031Z" fill="black"/>
<path d="M77.9812 49.3178C78.7334 49.3178 79.3432 48.708 79.3432 47.9558C79.3432 47.2036 78.7334 46.5938 77.9812 46.5938C77.2289 46.5938 76.6191 47.2036 76.6191 47.9558C76.6191 48.708 77.2289 49.3178 77.9812 49.3178Z" fill="black"/>
<path d="M68.8343 49.3178C69.5865 49.3178 70.1963 48.708 70.1963 47.9558C70.1963 47.2036 69.5865 46.5938 68.8343 46.5938C68.082 46.5938 67.4722 47.2036 67.4722 47.9558C67.4722 48.708 68.082 49.3178 68.8343 49.3178Z" fill="black"/>
<path d="M85.2305 52.9439C85.0621 52.9439 84.8936 52.9355 84.7156 52.9175C84.1897 52.8646 83.8061 52.3957 83.8589 51.8701C83.9117 51.3441 84.3757 50.9602 84.9063 51.013C85.0131 51.0237 85.121 51.0298 85.2305 51.0298C87.2958 51.0298 88.976 49.3497 88.976 47.2844C88.976 45.2187 87.2954 43.5386 85.2305 43.5386C85.1042 43.5386 84.9794 43.5458 84.8557 43.5585C84.3301 43.6121 83.8597 43.2308 83.8057 42.7048C83.751 42.1792 84.133 41.7087 84.659 41.6544C84.8512 41.6345 85.0433 41.6245 85.2301 41.6245C88.3508 41.6245 90.8896 44.1633 90.8896 47.2844C90.89 50.4047 88.3512 52.9439 85.2305 52.9439Z" fill="black"/>
<path d="M61.2576 52.9439C58.137 52.9439 55.5981 50.4051 55.5981 47.2844C55.5981 43.9677 58.4735 41.2892 61.8541 41.6575C62.3797 41.7145 62.7594 42.1865 62.7024 42.7121C62.6457 43.2373 62.1752 43.6167 61.6477 43.56C61.516 43.5459 61.3847 43.5386 61.2576 43.5386C59.1924 43.5386 57.5122 45.2188 57.5122 47.2844C57.5122 49.3497 59.1928 51.0299 61.2576 51.0299C61.3618 51.0299 61.4644 51.0241 61.5662 51.0146C62.0972 50.9633 62.56 51.3503 62.6097 51.8766C62.6599 52.403 62.274 52.87 61.748 52.9202C61.5861 52.9351 61.423 52.9439 61.2576 52.9439Z" fill="black"/>
<path d="M88.9266 97.6971C88.3979 97.6971 87.9695 97.2687 87.9695 96.7401V82.5771C87.9695 82.0485 88.3979 81.6201 88.9266 81.6201C89.4552 81.6201 89.8836 82.0485 89.8836 82.5771V96.7401C89.8836 97.2687 89.4552 97.6971 88.9266 97.6971Z" fill="black"/>
<path d="M57.7251 97.6971C57.1964 97.6971 56.768 97.2687 56.768 96.7401V82.5771C56.768 82.0485 57.1964 81.6201 57.7251 81.6201C58.2537 81.6201 58.6821 82.0485 58.6821 82.5771V96.7401C58.6821 97.2687 58.2537 97.6971 57.7251 97.6971Z" fill="black"/>
<path d="M95.8073 98H50.6809C50.0811 98 49.5003 97.75 49.088 97.314C48.6758 96.878 48.4579 96.2846 48.4912 95.6855L49.7369 74.2086C49.869 71.8374 51.5063 69.8529 53.812 69.2726L60.2849 67.6434C61.7343 67.2785 63.1491 66.7571 64.4905 66.0937C65.0379 65.8227 65.4988 65.5945 65.5762 65.5536C66.5121 65.0421 67.0978 64.0564 67.0978 62.9845V61.4571C67.0978 60.9284 67.5262 60.5001 68.0549 60.5001C68.5835 60.5001 69.0119 60.9284 69.0119 61.4571V62.9845C69.0119 64.7569 68.0449 66.3862 66.4876 67.2364C66.4566 67.2533 66.3992 67.285 65.3392 67.8091C63.8765 68.5326 62.333 69.1011 60.7523 69.4992L54.2794 71.1285C52.7906 71.5032 51.7337 72.7845 51.6483 74.3169L50.4026 95.7938C50.3969 95.8957 50.4478 95.9661 50.4784 95.9987C50.5163 96.0385 50.5822 96.0859 50.6809 96.0859H95.8076C95.9068 96.0859 95.9722 96.0385 96.0098 95.9987C96.0404 95.9661 96.0913 95.8957 96.0856 95.7912L94.8403 74.3192C94.7545 72.7849 93.6972 71.5036 92.2088 71.1289L85.7431 69.5015C84.1315 69.0957 82.5581 68.512 81.0655 67.7662C79.8746 67.171 79.7647 67.1001 79.6989 67.058C78.307 66.1611 77.4759 64.6387 77.4759 62.9845V61.3936C77.4759 60.8649 77.9043 60.4365 78.4329 60.4365C78.9616 60.4365 79.39 60.8649 79.39 61.3936V62.9845C79.39 63.9848 79.893 64.9062 80.7355 65.4491C80.7597 65.4628 80.9488 65.5681 81.9211 66.0543C83.2901 66.738 84.7333 67.2736 86.2105 67.6456L92.6762 69.273C94.9811 69.8533 96.6192 71.8378 96.7517 74.2109L97.9969 95.6832C98.0302 96.2846 97.8128 96.8784 97.4001 97.314C96.9875 97.7496 96.4071 98 95.8073 98Z" fill="black"/>
<path d="M73.2592 63.3874C72.9231 63.3874 72.5855 63.3736 72.2459 63.3464C65.9529 62.8392 61.0234 57.3883 61.0234 50.9372V42.5716C61.0234 41.6911 61.7086 40.9657 62.5833 40.9209C65.833 40.7528 68.7765 39.4976 70.8697 37.386C71.4983 36.7521 72.3412 36.4033 73.2439 36.4033C74.1466 36.4033 74.9899 36.7524 75.6181 37.386C77.7117 39.4976 80.6548 40.7528 83.9052 40.9209C84.7796 40.9661 85.4644 41.6911 85.4644 42.5716V51.1657C85.4644 54.5555 84.0254 57.8274 81.5165 60.1423C79.2276 62.2547 76.3267 63.3874 73.2592 63.3874ZM62.9371 42.8177V50.9376C62.9371 56.3984 67.0933 61.0109 72.3998 61.4389C75.2977 61.6728 78.0761 60.7131 80.2191 58.7358C82.3365 56.7823 83.5507 54.023 83.5507 51.1661V42.8177C79.9244 42.5731 76.6333 41.1284 74.2591 38.7343C73.9931 38.4655 73.6325 38.3178 73.2443 38.3178C72.8561 38.3178 72.4955 38.4655 72.2294 38.7343C69.8549 41.1284 66.5634 42.5727 62.9371 42.8177ZM83.8061 42.8327H83.8068C83.8065 42.8327 83.8065 42.8327 83.8061 42.8327Z" fill="black"/>
<path d="M62.9642 68.6566C62.8517 68.6566 62.7376 68.6367 62.6266 68.5949C57.263 66.5721 53.6386 63.1762 52.4197 59.0323C51.0856 54.4956 52.1693 52.2144 54.1366 48.0735C55.38 45.4558 56.9281 42.1977 58.5497 37.0378C59.9906 32.4528 62.2128 29.3586 65.1551 27.8411C68.2559 26.2417 71.2766 26.8339 72.8018 27.3289C73.0912 27.4227 73.397 27.4227 73.6864 27.3289C75.2116 26.8339 78.2331 26.2425 81.3331 27.8411C84.2754 29.3586 86.4976 32.4528 87.9385 37.0378C89.5601 42.1977 91.1082 45.4554 92.3516 48.0735C94.3193 52.2144 95.403 54.4956 94.0685 59.0323C92.8539 63.1617 89.2466 66.5511 83.9114 68.5762C83.4175 68.7634 82.8651 68.5149 82.6772 68.0211C82.4896 67.5269 82.738 66.9745 83.2323 66.7865C87.9941 64.9793 91.1905 62.0335 92.2322 58.4921C93.3603 54.6567 92.5024 52.851 90.6228 48.8946C89.4204 46.3642 87.7743 42.8986 86.1125 37.6116C84.8301 33.532 82.9272 30.8167 80.4557 29.5419C77.9728 28.261 75.519 28.7461 74.2771 29.1492C73.6011 29.3685 72.8871 29.3685 72.2115 29.1492C70.9692 28.7461 68.5158 28.261 66.0329 29.5419C63.5614 30.8167 61.6581 33.5316 60.3761 37.6116C58.7143 42.8986 57.0678 46.3642 55.8658 48.8946C53.9861 52.851 53.1283 54.6567 54.2564 58.4921C55.3015 62.0465 58.5144 64.9984 63.3023 66.8037C63.7969 66.9902 64.0465 67.5426 63.86 68.0372C63.7149 68.42 63.3505 68.6566 62.9642 68.6566Z" fill="black"/>
<path d="M25.0727 56.1031C23.5686 56.1031 22.1537 55.4864 21.2874 54.4528C20.9479 54.0478 21.0011 53.4441 21.4065 53.1046C21.8107 52.765 22.4152 52.8178 22.7544 53.2232C23.2612 53.8281 24.1279 54.1887 25.0727 54.1887C26.0175 54.1887 26.8838 53.8277 27.391 53.2232C27.7309 52.8178 28.335 52.7654 28.7389 53.1046C29.1443 53.4441 29.1971 54.0478 28.8579 54.4528C27.9916 55.4864 26.5768 56.1031 25.0727 56.1031Z" fill="black"/>
<path d="M29.4934 49.3178C30.2457 49.3178 30.8555 48.708 30.8555 47.9558C30.8555 47.2036 30.2457 46.5938 29.4934 46.5938C28.7412 46.5938 28.1314 47.2036 28.1314 47.9558C28.1314 48.708 28.7412 49.3178 29.4934 49.3178Z" fill="black"/>
<path d="M20.3465 49.3178C21.0988 49.3178 21.7086 48.708 21.7086 47.9558C21.7086 47.2036 21.0988 46.5938 20.3465 46.5938C19.5943 46.5938 18.9845 47.2036 18.9845 47.9558C18.9845 48.708 19.5943 49.3178 20.3465 49.3178Z" fill="black"/>
<path d="M36.7423 52.9439C36.575 52.9439 36.4005 52.9351 36.224 52.9171C35.698 52.8639 35.3148 52.3946 35.368 51.8686C35.4212 51.343 35.8936 50.9594 36.4162 51.0126C36.5234 51.0233 36.6317 51.0298 36.7423 51.0298C38.8076 51.0298 40.4878 49.3497 40.4878 47.2844C40.4878 45.2187 38.8072 43.5386 36.7423 43.5386C36.6508 43.5386 36.5601 43.5424 36.4705 43.5493C35.9442 43.5899 35.4833 43.1956 35.4427 42.6684C35.4021 42.1417 35.7964 41.6812 36.3232 41.6406C36.4613 41.6299 36.6015 41.6245 36.7423 41.6245C39.863 41.6245 42.4018 44.1633 42.4018 47.2844C42.4022 50.4047 39.8634 52.9439 36.7423 52.9439Z" fill="black"/>
<path d="M12.7695 52.9439C9.6488 52.9439 7.10999 50.4051 7.10999 47.2844C7.10999 44.1637 9.6488 41.6245 12.7695 41.6245C12.9957 41.6245 13.2289 41.6394 13.4631 41.6685C13.9872 41.734 14.3597 42.2121 14.2938 42.737C14.228 43.2618 13.7495 43.6327 13.2254 43.5677C13.0704 43.5485 12.9169 43.5386 12.7699 43.5386C10.7046 43.5386 9.02443 45.2187 9.02443 47.2844C9.02443 49.3497 10.705 51.0298 12.7699 51.0298C12.8683 51.0298 12.9655 51.0249 13.0616 51.016C13.5872 50.9682 14.0542 51.3545 14.1028 51.8808C14.1514 52.4072 13.7644 52.8735 13.238 52.9221C13.0834 52.9362 12.928 52.9439 12.7695 52.9439Z" fill="black"/>
<path d="M40.4388 97.6971C39.9101 97.6971 39.4818 97.2687 39.4818 96.7401V82.5771C39.4818 82.0485 39.9101 81.6201 40.4388 81.6201C40.9674 81.6201 41.3958 82.0485 41.3958 82.5771V96.7401C41.3958 97.2687 40.9674 97.6971 40.4388 97.6971Z" fill="black"/>
<path d="M9.23727 97.6971C8.70861 97.6971 8.28024 97.2687 8.28024 96.7401V82.5771C8.28024 82.0485 8.70861 81.6201 9.23727 81.6201C9.76594 81.6201 10.1943 82.0485 10.1943 82.5771V96.7401C10.1943 97.2687 9.76594 97.6971 9.23727 97.6971Z" fill="black"/>
<path d="M47.3191 98H2.19276C1.59327 98 1.01255 97.75 0.600256 97.314C0.187967 96.878 -0.0298532 96.2846 0.00345148 95.6855L1.24874 74.2086C1.38119 71.8374 3.01887 69.8529 5.32416 69.2726L11.7971 67.6434C13.2465 67.2785 14.6613 66.7571 16.0027 66.0937C16.5501 65.8227 17.011 65.5945 17.0884 65.5536C18.0244 65.0421 18.6101 64.0564 18.6101 62.9845V61.4414C18.6101 60.9127 19.0384 60.4844 19.5671 60.4844C20.0957 60.4844 20.5241 60.9127 20.5241 61.4414V62.9849C20.5241 64.7569 19.5571 66.3862 18.0002 67.2368C17.9688 67.2537 17.9114 67.2854 16.8514 67.8095C15.3887 68.533 13.8452 69.1015 12.2646 69.4996L5.79158 71.1289C4.3032 71.5036 3.24587 72.7849 3.16012 74.3173L1.91483 95.7938C1.90909 95.8961 1.96001 95.9661 1.99063 95.9987C2.02815 96.0385 2.09361 96.0859 2.19276 96.0859H47.3195C47.4186 96.0859 47.4845 96.0385 47.522 95.9987C47.5526 95.9661 47.6035 95.8957 47.5978 95.7916L46.3521 74.3196C46.2667 72.7849 45.2098 71.5036 43.721 71.1289L37.2553 69.5015C35.6437 69.0957 34.0703 68.512 32.5777 67.7662C31.3868 67.171 31.2769 67.1001 31.2111 67.058C29.8192 66.1611 28.9881 64.6387 28.9881 62.9845V61.5846C28.9881 61.0559 29.4165 60.6275 29.9451 60.6275C30.4738 60.6275 30.9022 61.0559 30.9022 61.5846V62.9845C30.9022 63.9848 31.4052 64.9062 32.2477 65.4491C32.2719 65.4628 32.461 65.5681 33.4333 66.0543C34.8023 66.738 36.2455 67.2736 37.7227 67.6456L44.1884 69.273C46.4937 69.8533 48.1314 71.8378 48.2635 74.2113L49.5091 95.6836C49.5424 96.285 49.325 96.8787 48.9123 97.3144C48.4997 97.75 47.9193 98 47.3191 98Z" fill="black"/>
<path d="M24.7714 63.3874C24.4353 63.3874 24.0977 63.3736 23.7581 63.3464C17.4651 62.8392 12.5356 57.3883 12.5356 50.9372V42.5516C12.5356 41.8607 12.8326 41.203 13.3514 40.7471C13.8655 40.295 14.5469 40.0856 15.2248 40.1732C15.3382 40.1878 15.4538 40.1954 15.569 40.1954C16.3989 40.1954 17.1684 39.8187 17.6802 39.1622C18.3719 38.2741 19.5786 37.9755 20.6125 38.436C21.4903 38.8265 22.5515 38.7028 23.3198 38.1122C24.1654 37.4622 25.3476 37.4618 26.1932 38.1122C26.9615 38.7025 28.0226 38.8265 28.9004 38.4356C29.9348 37.9751 31.141 38.2737 31.8332 39.1622C32.3446 39.8187 33.1141 40.1954 33.944 40.1954C34.0596 40.1954 34.1752 40.1878 34.2874 40.1732H34.2881C34.9619 40.0856 35.6467 40.295 36.1609 40.7471C36.6792 41.203 36.977 41.861 36.977 42.5516V51.1657C36.977 54.5555 35.538 57.8274 33.0291 60.1423C30.7395 62.2546 27.8389 63.3874 24.7714 63.3874ZM14.919 42.0678C14.772 42.0678 14.6679 42.1386 14.6154 42.1845C14.5534 42.2389 14.4493 42.3591 14.4493 42.5516V50.9372C14.4493 56.398 18.6055 61.0105 23.912 61.4385C26.8099 61.6716 29.588 60.7127 31.731 58.7358C33.8483 56.7823 35.0626 54.023 35.0626 51.1661V42.552C35.0626 42.3595 34.9588 42.2393 34.8964 42.1845C34.8375 42.1328 34.7119 42.0471 34.5331 42.072C34.3394 42.0969 34.1411 42.1099 33.9436 42.1099C32.5192 42.1099 31.1996 41.4645 30.323 40.339C30.1683 40.1395 29.9141 40.0798 29.6775 40.1851C29.0903 40.4466 28.4648 40.5786 27.8186 40.5786C26.7996 40.5786 25.8337 40.2506 25.0264 39.63C24.8694 39.5094 24.6428 39.5094 24.4858 39.63C23.6785 40.2506 22.7127 40.5786 21.6936 40.5786C21.0474 40.5786 20.4219 40.4462 19.8347 40.1851C19.5985 40.0802 19.3451 40.1399 19.1896 40.339C18.3126 41.4645 16.9931 42.1099 15.5686 42.1099C15.3711 42.1099 15.1728 42.0972 14.9791 42.072C14.958 42.0689 14.9381 42.0678 14.919 42.0678Z" fill="black"/>
<path d="M9.25067 45.0048C8.72201 45.0048 8.29364 44.5765 8.29364 44.0478V41.71C8.29364 33.0163 14.9343 25.6705 23.4109 24.9868C28.0403 24.6113 32.4782 26.146 35.8998 29.3026C39.2796 32.421 41.2186 36.8287 41.2186 41.3949V43.9598C41.2186 44.4884 40.7902 44.9168 40.2615 44.9168C39.7329 44.9168 39.3045 44.4884 39.3045 43.9598V41.3949C39.3045 37.3616 37.5903 33.4669 34.602 30.7095C31.5774 27.9191 27.654 26.5636 23.5652 26.8944C16.0751 27.4984 10.2077 34.0062 10.2077 41.71V44.0478C10.2077 44.5765 9.77934 45.0048 9.25067 45.0048Z" fill="black"/>
<path d="M49 31.0078C41.1899 31.0078 34.8359 24.6539 34.8359 16.8438C34.8359 9.03361 41.1899 2.67969 49 2.67969C56.8101 2.67969 63.1641 9.03361 63.1641 16.8438C63.1641 24.6539 56.8101 31.0078 49 31.0078ZM49 4.59375C42.2453 4.59375 36.75 10.089 36.75 16.8438C36.75 23.5985 42.2453 29.0938 49 29.0938C55.7547 29.0938 61.25 23.5985 61.25 16.8438C61.25 10.089 55.7547 4.59375 49 4.59375Z" fill="black"/>
<path d="M24.766 76.104C24.4154 76.104 24.0628 76.0898 23.7079 76.0611C19.1976 75.6974 15.1689 72.8658 13.194 68.6713C12.9689 68.1932 13.1737 67.6228 13.6518 67.3977C14.1311 67.1722 14.7004 67.3778 14.9255 67.8559C16.6091 71.4314 20.0333 73.8446 23.8618 74.1532C28.4047 74.5191 32.6221 72.0871 34.6016 67.9554C34.8302 67.4788 35.4013 67.2775 35.8783 67.506C36.3549 67.7345 36.5563 68.3057 36.3281 68.7827C34.1576 73.3129 29.6951 76.104 24.766 76.104Z" fill="black"/>
<path d="M24.7527 72.4879C24.4736 72.4879 24.1926 72.4768 23.9108 72.4539C20.6684 72.1924 17.7973 70.4269 16.0337 67.6098C15.7535 67.1619 15.889 66.5712 16.3373 66.2906C16.7844 66.0108 17.3755 66.1459 17.6561 66.5938C19.0955 68.8933 21.4314 70.3339 24.0647 70.5459C27.2229 70.8013 30.2364 69.2781 31.9223 66.572C32.2018 66.1229 32.7921 65.987 33.2408 66.2657C33.6894 66.5452 33.8261 67.1355 33.547 67.5841C31.631 70.66 28.3166 72.4879 24.7527 72.4879Z" fill="black"/>
<path d="M47.5645 85.3672H40.7217C40.193 85.3672 39.7646 84.9388 39.7646 84.4102C39.7646 83.8815 40.193 83.4531 40.7217 83.4531H47.5645C48.0931 83.4531 48.5215 83.8815 48.5215 84.4102C48.5215 84.9388 48.0931 85.3672 47.5645 85.3672Z" fill="black"/>
<path d="M8.80471 85.3672H1.89801C1.36935 85.3672 0.940979 84.9388 0.940979 84.4102C0.940979 83.8815 1.36935 83.4531 1.89801 83.4531H8.80471C9.33338 83.4531 9.76174 83.8815 9.76174 84.4102C9.76174 84.9388 9.33338 85.3672 8.80471 85.3672Z" fill="black"/>
<path d="M8.80469 88.8125H1.6748C1.14614 88.8125 0.717773 88.3841 0.717773 87.8555C0.717773 87.3268 1.14614 86.8984 1.6748 86.8984H8.80469C9.33335 86.8984 9.76172 87.3268 9.76172 87.8555C9.76172 88.3841 9.33335 88.8125 8.80469 88.8125Z" fill="black"/>
<path d="M47.6601 88.8125H40.8415C40.3128 88.8125 39.8845 88.3841 39.8845 87.8555C39.8845 87.3268 40.3128 86.8984 40.8415 86.8984H47.6601C48.1888 86.8984 48.6172 87.3268 48.6172 87.8555C48.6172 88.3841 48.1888 88.8125 47.6601 88.8125Z" fill="black"/>
<path d="M73.255 75.3575C72.7799 75.3575 72.3022 75.3383 71.8221 75.2997C67.3325 74.9375 63.2096 72.829 60.2126 69.3623C59.8673 68.9626 59.9109 68.3581 60.311 68.0125C60.711 67.6668 61.3147 67.7104 61.6608 68.1105C64.327 71.1944 67.9902 73.0702 71.976 73.3917C76.8864 73.788 81.585 71.8819 84.8592 68.1618C85.2087 67.7648 85.8132 67.7265 86.2101 68.0756C86.6067 68.4248 86.6454 69.0296 86.2963 69.4262C82.9382 73.2409 78.2476 75.3575 73.255 75.3575Z" fill="black"/>
<path d="M89.154 85.0874C88.7409 85.0874 88.3596 84.8179 88.2368 84.4014C88.0867 83.8946 88.3765 83.3621 88.8833 83.2124L95.7108 81.195C96.2172 81.0445 96.7501 81.3347 96.8994 81.8416C97.0495 82.3484 96.7597 82.8809 96.2529 83.0306L89.4254 85.048C89.3347 85.0748 89.2436 85.0874 89.154 85.0874Z" fill="black"/>
<path d="M57.4548 85.0444C57.3652 85.0444 57.2737 85.0318 57.1834 85.005L50.3008 82.9711C49.7939 82.8214 49.5041 82.2889 49.6542 81.7821C49.8039 81.2752 50.3368 80.9862 50.8428 81.1355L57.7254 83.1694C58.2323 83.3191 58.5221 83.8516 58.372 84.3584C58.2491 84.7745 57.8678 85.0444 57.4548 85.0444Z" fill="black"/>
</svg>

  );
};

export default Educational;
