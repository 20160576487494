import React from "react";

const Psychologist = () => {
  return (
    <svg width="98" height="98" viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_565_667)">
<path d="M38.0667 60.5125V57.1228C34.7909 55.3374 32.4967 51.9603 32.2341 48.0496C31.9908 48.0888 31.743 48.1146 31.4888 48.1146C30.2346 48.1146 29.0986 47.6174 28.2603 46.8125C27.775 49.6097 26.7993 53.3125 24.8362 56.894C24.4783 57.547 24.6521 58.3634 25.2555 58.7998C26.8388 59.9445 30.4313 61.9105 37.1402 62.7176C37.7204 62.1431 38.0667 61.356 38.0667 60.5125V60.5125Z" fill="#754E34"/>
<path d="M61.9303 56.894C59.9672 53.3125 58.9914 49.6097 58.5061 46.8125C57.6675 47.6174 56.5315 48.1146 55.2777 48.1146C55.0235 48.1146 54.7757 48.0888 54.5324 48.0496C54.2698 51.9603 51.9755 55.3377 48.6998 57.1228V60.5125C48.6998 61.356 49.046 62.1435 49.6266 62.7179C56.3355 61.9108 59.9276 59.9448 61.5113 58.8001C62.1143 58.3634 62.2881 57.547 61.9303 56.894V56.894Z" fill="#754E34"/>
<path d="M36.9397 63.4976C36.9066 63.4976 36.8728 63.4953 36.839 63.4912C30.0358 62.6412 26.3661 60.5951 24.7841 59.4514C23.8585 58.782 23.5773 57.5163 24.1308 56.5071C25.6636 53.7105 26.806 50.3749 27.4349 46.8604C27.5131 46.423 27.9301 46.1298 28.3685 46.21C28.8058 46.2882 29.097 46.7062 29.0188 47.1436C28.3504 50.8798 27.1806 54.2904 25.5419 57.2801C25.3778 57.5794 25.4573 57.9521 25.727 58.1471C27.1842 59.2007 30.5916 61.0888 37.0388 61.894C37.4797 61.949 37.7925 62.3513 37.7372 62.7922C37.6863 63.1996 37.3397 63.4976 36.9397 63.4976V63.4976Z" fill="black"/>
<path d="M49.8268 63.4973C49.4267 63.4973 49.0805 63.1993 49.0293 62.7926C48.9739 62.3517 49.2871 61.9494 49.728 61.8944C56.1752 61.0892 59.5823 59.2011 61.0391 58.1475C61.3088 57.9525 61.3886 57.5798 61.2245 57.2805C59.5794 54.2792 58.407 50.8551 57.7402 47.1031C57.6623 46.6657 57.9539 46.248 58.3912 46.1701C58.8273 46.0929 59.2463 46.3841 59.3238 46.8215C59.7615 49.2824 60.6912 52.9604 62.6353 56.5072C63.1885 57.5164 62.9076 58.7821 61.9817 59.4515C60.4 60.5952 56.7307 62.6413 49.9268 63.4912C49.8937 63.4951 49.8599 63.4973 49.8268 63.4973V63.4973Z" fill="black"/>
<path d="M55.2777 38.7812C55.0334 38.7812 54.7943 38.8018 54.5604 38.8382V47.2997C54.5604 47.5517 54.5488 47.8011 54.532 48.0492C54.7753 48.0885 55.0231 48.1142 55.2774 48.1142C57.8548 48.1142 59.944 46.025 59.944 43.4476C59.944 40.8705 57.8548 38.7812 55.2777 38.7812Z" fill="#F9D0B4"/>
<path d="M48.7294 35.1305C47.027 37.1173 40.9907 43.1456 32.2061 40.6432V47.2996C32.2061 53.4469 37.2358 58.4769 43.3834 58.4769C49.5308 58.4769 54.5607 53.4473 54.5607 47.2996V38.2694C54.5607 37.8588 54.2734 37.5106 53.8733 37.4179C52.7422 37.156 51.369 36.5007 49.795 35.0706C49.4831 34.7877 49.003 34.8109 48.7294 35.1305V35.1305Z" fill="#F9D0B4"/>
<path d="M32.2058 40.643C31.4382 40.4245 30.6501 40.1413 29.8417 39.7844C29.3207 39.5543 28.7118 39.6602 28.296 40.0499C27.3895 40.8998 26.8218 42.1063 26.8218 43.4473C26.8218 46.0244 28.911 48.114 31.4884 48.114C31.7427 48.114 31.9905 48.0882 32.2338 48.049C32.217 47.8009 32.2054 47.5515 32.2054 47.2995V40.643H32.2058Z" fill="#F9D0B4"/>
<path d="M28.2964 40.0506C28.7118 39.6609 29.3207 39.555 29.842 39.7851C30.6504 40.142 31.4386 40.4249 32.2061 40.6437C40.9907 43.1461 47.0273 37.1179 48.7294 35.131C49.0029 34.8117 49.4831 34.7883 49.7943 35.0711C51.3686 36.5016 52.7418 37.1565 53.8727 37.4185C54.2727 37.5111 54.5601 37.8593 54.5601 38.27V38.8383C54.794 38.8019 55.0331 38.7813 55.2774 38.7813C56.5685 38.7813 57.737 39.3059 58.5818 40.1532V37.4197C58.5818 29.0603 51.7422 22.2207 43.3828 22.2207C35.0233 22.2207 28.1837 29.0603 28.1837 37.4197V40.1664C28.222 40.1285 28.2571 40.0873 28.2964 40.0506V40.0506Z" fill="#754E34"/>
<path d="M55.5803 85.8136C49.9285 80.1618 49.5086 71.2649 54.311 65.1253C53.9306 64.9934 53.5576 64.8405 53.1962 64.6622L50.4328 63.2997C49.3715 62.7764 48.6995 61.6957 48.6995 60.5127V57.123C47.1162 57.9862 45.304 58.4772 43.3831 58.4772C41.4619 58.4772 39.6501 57.9862 38.0667 57.123V60.5127C38.0667 61.696 37.3948 62.7764 36.3334 63.2997L33.57 64.6622C32.9524 64.9667 32.3043 65.2051 31.6362 65.3731L23.6445 67.3906C21.6995 67.8801 20.2989 69.5776 20.1873 71.5803L19.3042 95.851C19.2633 96.5812 19.8449 97.1959 20.5764 97.1959H27.6531C28.1287 97.1959 28.5146 96.8103 28.5146 96.3344V81.163L29.7642 85.1661C30.5813 87.7834 30.9967 90.5095 30.9967 93.2514V96.3344C30.9967 96.81 31.3823 97.1959 31.8583 97.1959H41.6238H45.1431H54.9086C55.3843 97.1959 55.7701 96.8103 55.7701 96.3344V93.2514C55.7701 91.0472 56.0434 88.8547 56.5731 86.7192C56.2329 86.4327 55.9001 86.1334 55.5803 85.8136V85.8136Z" fill="#F9D0B4"/>
<path d="M58.252 87.9541V96.3342C58.252 96.8098 58.6375 97.1957 59.1135 97.1957H66.1902C66.9217 97.1957 67.5029 96.581 67.4623 95.8508L67.2634 90.3816C64.138 90.5045 60.9848 89.6945 58.252 87.9541V87.9541Z" fill="#DAE6F1"/>
<path d="M55.5802 85.8128C49.9284 80.1611 49.5085 71.2642 54.3109 65.1246C53.9305 64.9926 53.5576 64.8398 53.1962 64.6615L50.4327 63.2989C50.3446 63.2555 50.2635 63.2027 50.1811 63.1519L43.6012 88.8115C43.4567 89.3747 43.3834 89.9626 43.3834 90.5541V97.1951H45.143H54.9085C55.3842 97.1951 55.77 96.8096 55.77 96.3336V93.2506C55.77 91.0465 56.0433 88.854 56.573 86.7184C56.2328 86.432 55.9 86.1327 55.5802 85.8128Z" fill="#DAE6F1"/>
<path d="M36.5851 63.1519C36.5027 63.2027 36.4216 63.2555 36.3334 63.2989L33.57 64.6615C32.9524 64.9659 32.3043 65.2044 31.6362 65.3724L23.6445 67.3898C21.6995 67.8793 20.2989 69.5769 20.1873 71.5796L19.3042 95.8503C19.2633 96.5805 19.8449 97.1951 20.5764 97.1951H27.6531C28.1287 97.1951 28.5146 96.8096 28.5146 96.3336V81.1623L29.7642 85.1653C30.5813 87.7827 30.9967 90.5088 30.9967 93.2506V96.3336C30.9967 96.8093 31.3823 97.1951 31.8582 97.1951H41.6237H43.3834V90.5541C43.3834 89.9626 43.3097 89.375 43.1656 88.8115L36.5851 63.1519Z" fill="#DAE6F1"/>
<path d="M54.3139 65.1207C53.9329 64.9888 53.558 64.8401 53.1959 64.6615L50.4325 63.2989C50.3443 63.2555 50.2632 63.2027 50.1808 63.1519L43.601 88.8112C43.4964 89.2189 43.4304 89.6395 43.4011 90.0653L51.779 79.6288C50.1908 74.7784 51.0359 69.3085 54.3139 65.1207V65.1207Z" fill="#EDF4FC"/>
<path d="M43.1652 88.8117L36.5854 63.1523C36.503 63.2032 36.4219 63.256 36.3337 63.2994L33.5703 64.662C33.1191 64.8844 32.6512 65.0707 32.1717 65.221L31.4913 69.8831C31.4408 70.2287 31.5927 70.5705 31.8543 70.7009L34.2068 71.8732C34.5367 72.0377 34.6773 72.5239 34.5071 72.9127L32.9858 76.387C32.86 76.6747 32.9012 77.0313 33.0872 77.263L43.365 90.0664C43.3358 89.64 43.2698 89.2194 43.1652 88.8117V88.8117Z" fill="#EDF4FC"/>
<path d="M43.1652 88.8123C43.2128 88.9977 43.2498 89.1866 43.2817 89.3764C43.2884 89.4163 43.2946 89.4562 43.3007 89.4965C43.3293 89.685 43.3522 89.8749 43.3654 90.0664C43.3766 90.2286 43.3834 90.3918 43.3834 90.5549C43.3834 90.3914 43.3901 90.2286 43.4014 90.0664C43.4146 89.8749 43.4374 89.685 43.4661 89.4965C43.4722 89.4562 43.4783 89.4167 43.4851 89.3764C43.5172 89.1866 43.5543 88.9977 43.6016 88.8123L48.4352 69.9629C46.979 70.9029 45.246 71.4506 43.3837 71.4506C41.5213 71.4506 39.7884 70.9026 38.3322 69.9629L43.1652 88.8123Z" fill="#3CAADC"/>
<path d="M66.6446 90.3967C75.2864 90.3967 82.2919 83.3911 82.2919 74.7494C82.2919 66.1076 75.2864 59.1021 66.6446 59.1021C58.0028 59.1021 50.9973 66.1076 50.9973 74.7494C50.9973 83.3911 58.0028 90.3967 66.6446 90.3967Z" fill="#F98FA6"/>
<path d="M66.6445 85.8513C72.7763 85.8513 77.7472 80.8805 77.7472 74.7486C77.7472 68.6168 72.7763 63.646 66.6445 63.646C60.5127 63.646 55.5419 68.6168 55.5419 74.7486C55.5419 80.8805 60.5127 85.8513 66.6445 85.8513Z" fill="#FC657E"/>
<path d="M55.2773 48.9191C55.1119 48.9191 54.9449 48.9104 54.7666 48.8921C54.3248 48.8467 54.003 48.4518 54.0483 48.0097C54.0937 47.5678 54.4873 47.245 54.9307 47.2914C55.054 47.3039 55.1673 47.31 55.2773 47.31C57.4068 47.31 59.1395 45.5774 59.1395 43.4479C59.1395 41.3185 57.4068 39.5861 55.2773 39.5861C55.1769 39.5861 55.0778 39.5903 54.979 39.5984C54.5433 39.6325 54.1484 39.3036 54.113 38.8608C54.0776 38.4179 54.4078 38.0298 54.8509 37.9944C54.9919 37.9832 55.1338 37.9771 55.2773 37.9771C58.294 37.9771 60.7485 40.4312 60.7485 43.4479C60.7485 46.4646 58.294 48.9191 55.2773 48.9191Z" fill="black"/>
<path d="M43.3831 59.2817C36.7762 59.2817 31.4012 53.9067 31.4012 47.2998V40.6434C31.4012 40.3911 31.5197 40.1533 31.7211 40.0014C31.9226 39.8492 32.1839 39.8002 32.4262 39.8697C40.7664 42.2454 46.5459 36.4427 48.1183 34.6074C48.3915 34.2885 48.7725 34.0986 49.1912 34.0722C49.6099 34.0465 50.025 34.1932 50.3356 34.4755C51.6071 35.6308 52.8583 36.3574 54.0548 36.6345C54.8262 36.8135 55.3649 37.4861 55.3649 38.2697V47.2998C55.3649 53.9067 49.9896 59.2817 43.3831 59.2817V59.2817ZM33.0103 41.6758V47.2998C33.0103 53.0195 37.6635 57.6726 43.3831 57.6726C49.1024 57.6726 53.7555 53.0195 53.7555 47.2998V38.2697C53.7555 38.2333 53.723 38.2095 53.6912 38.2021C52.2378 37.8655 50.7597 37.0255 49.2961 35.7051C47.5722 37.7001 41.6414 43.5677 33.0103 41.6758V41.6758Z" fill="black"/>
<path d="M31.4887 48.9186C28.472 48.9186 26.0179 46.4645 26.0179 43.4474C26.0179 41.9452 26.648 40.4928 27.7464 39.463C28.3996 38.8503 29.3496 38.6878 30.1674 39.0485C30.9175 39.3797 31.6777 39.6561 32.4265 39.8692C32.8539 39.9908 33.1017 40.4359 32.98 40.8632C32.8584 41.2909 32.412 41.5384 31.986 41.4168C31.166 41.1831 30.3354 40.8816 29.5173 40.5205C29.2914 40.4207 29.0285 40.4664 28.847 40.6367C28.0604 41.3743 27.627 42.3726 27.627 43.4471C27.627 45.5766 29.3593 47.3092 31.4887 47.3092C31.6052 47.3092 31.725 47.3025 31.8553 47.2883C32.2965 47.241 32.694 47.5602 32.7413 48.0021C32.7886 48.4439 32.4693 48.8404 32.0275 48.888C31.8395 48.9086 31.6632 48.9186 31.4887 48.9186V48.9186Z" fill="black"/>
<path d="M28.184 40.7641C27.7396 40.7641 27.3795 40.404 27.3795 39.9595V37.4201C27.3795 28.5956 34.5585 21.4165 43.383 21.4165C52.2072 21.4165 59.3866 28.5956 59.3866 37.4201V39.8723C59.3866 40.3167 59.0265 40.6769 58.5821 40.6769C58.1376 40.6769 57.7775 40.3167 57.7775 39.8723V37.4201C57.7775 29.4828 51.3203 23.0256 43.383 23.0256C35.4458 23.0256 28.9885 29.4828 28.9885 37.4201V39.9595C28.9885 40.404 28.6284 40.7641 28.184 40.7641Z" fill="black"/>
<path d="M54.0603 65.8396C53.9663 65.8396 53.8707 65.8232 53.7777 65.7882C53.4507 65.6656 53.1357 65.5297 52.8402 65.3843L50.0768 64.0214C48.731 63.3575 47.8949 62.0133 47.8949 60.513V57.3817C47.8949 56.9373 48.255 56.5771 48.6994 56.5771C49.1435 56.5771 49.504 56.9373 49.504 57.3817V60.513C49.504 61.3963 49.9957 62.1877 50.788 62.5784L53.5515 63.9409C53.7993 64.0629 54.0657 64.1778 54.3425 64.2817C54.7586 64.4378 54.9694 64.9016 54.8133 65.3177C54.6923 65.6404 54.3859 65.8396 54.0603 65.8396V65.8396Z" fill="black"/>
<path d="M54.9086 98.0003H31.8579C30.9395 98.0003 30.1919 97.2531 30.1919 96.3343V93.2513C30.1919 90.9774 29.8984 88.7205 29.3188 86.5267V96.3343C29.3188 97.2528 28.5715 98.0003 27.6531 98.0003H20.5763C20.0074 98.0003 19.4571 97.7635 19.0661 97.35C18.6754 96.9368 18.4691 96.3739 18.501 95.8062L19.3834 71.551C19.516 69.1686 21.1492 67.1891 23.4482 66.6104L31.4396 64.5933C32.052 64.4392 32.6489 64.2197 33.2144 63.941L35.9778 62.5784C36.7701 62.1877 37.2622 61.3964 37.2622 60.513V57.3714C37.2622 56.927 37.6223 56.5669 38.0667 56.5669C38.5111 56.5669 38.8713 56.927 38.8713 57.3714V60.513C38.8713 62.0136 38.0352 63.3578 36.6893 64.0214L33.9259 65.384C33.2588 65.7129 32.5546 65.972 31.8328 66.1535L23.8415 68.1706C22.2285 68.5767 21.0832 69.9647 20.9905 71.625L20.1081 95.8802C20.0975 96.0714 20.1831 96.1895 20.2352 96.2445C20.287 96.2992 20.4003 96.3916 20.5763 96.3916H27.6531C27.6843 96.3916 27.7097 96.3662 27.7097 96.3346V81.1633C27.7097 80.7658 28 80.4279 28.3929 80.3677C28.7859 80.3076 29.164 80.5441 29.2821 80.9235L30.5317 84.9263C31.3739 87.624 31.801 90.4251 31.801 93.2516V96.3346C31.801 96.3662 31.8264 96.3916 31.8579 96.3916H54.9083C54.9398 96.3916 54.9653 96.3662 54.9653 96.3346V93.2516C54.9653 91.0691 55.2269 88.8737 55.7424 86.7271C55.8461 86.2949 56.2808 86.0282 56.7127 86.1328C57.1449 86.2364 57.4107 86.6708 57.3071 87.103C56.8208 89.1266 56.5747 91.1952 56.5747 93.2516V96.3346C56.5743 97.2531 55.8271 98.0003 54.9086 98.0003V98.0003Z" fill="black"/>
<path d="M43.383 53.5114C41.8461 53.5114 40.4024 52.8839 39.5216 51.8331C39.2361 51.4927 39.2809 50.9852 39.6213 50.6997C39.9625 50.4139 40.4697 50.4593 40.7548 50.7995C41.3334 51.4901 42.3162 51.9023 43.383 51.9023C44.4499 51.9023 45.4324 51.4901 46.0113 50.7995C46.2971 50.459 46.8043 50.4143 47.1448 50.6997C47.4852 50.9852 47.53 51.4927 47.2445 51.8331C46.3634 52.8842 44.92 53.5114 43.383 53.5114V53.5114Z" fill="black"/>
<path d="M48.2461 46.1878C49.0459 46.1878 49.6943 45.5395 49.6943 44.7397C49.6943 43.9399 49.0459 43.2915 48.2461 43.2915C47.4463 43.2915 46.7979 43.9399 46.7979 44.7397C46.7979 45.5395 47.4463 46.1878 48.2461 46.1878Z" fill="black"/>
<path d="M38.5204 46.1878C39.3202 46.1878 39.9686 45.5395 39.9686 44.7397C39.9686 43.9399 39.3202 43.2915 38.5204 43.2915C37.7206 43.2915 37.0723 43.9399 37.0723 44.7397C37.0723 45.5395 37.7206 46.1878 38.5204 46.1878Z" fill="black"/>
<path d="M66.1898 98.0001H59.1134C58.195 98.0001 57.4474 97.2529 57.4474 96.3341V88.1581C57.4474 87.7136 57.8075 87.3535 58.2519 87.3535C58.6964 87.3535 59.0565 87.7136 59.0565 88.1581V96.3341C59.0565 96.3656 59.0819 96.3911 59.1134 96.3911H66.1898C66.3659 96.3911 66.4788 96.299 66.531 96.244C66.5831 96.189 66.6687 96.0709 66.6587 95.8951L66.4695 90.6962C66.4531 90.2521 66.8 89.8791 67.2441 89.863C67.6831 89.8498 68.0612 90.1935 68.0773 90.638L68.2659 95.8214C68.2968 96.374 68.0908 96.9369 67.6998 97.3501C67.3091 97.7633 66.7588 98.0001 66.1898 98.0001V98.0001Z" fill="black"/>
<path d="M43.3831 97.6783C42.9387 97.6783 42.5786 97.3182 42.5786 96.8737V90.5543C42.5786 90.0297 42.5135 89.5106 42.3858 89.0115L35.8613 63.5687C35.7509 63.1384 36.0103 62.6998 36.4409 62.5894C36.8718 62.4803 37.3098 62.7387 37.4202 63.169L43.9447 88.6121C44.1059 89.2416 44.1876 89.8952 44.1876 90.5546V96.8737C44.1876 97.3182 43.8275 97.6783 43.3831 97.6783V97.6783Z" fill="black"/>
<path d="M43.4011 90.87C43.303 90.87 43.2038 90.852 43.1086 90.815C42.781 90.6869 42.5747 90.3612 42.5988 90.0105C42.6317 89.5322 42.7066 89.0614 42.8219 88.6119L49.3492 63.1585C49.4599 62.7279 49.8999 62.4692 50.3285 62.5789C50.7588 62.6893 51.0185 63.1279 50.9081 63.5582L44.9275 86.8795L50.9812 79.3387C51.2586 78.9925 51.7654 78.9368 52.1123 79.2148C52.4586 79.4929 52.514 79.9994 52.2359 80.346L44.0293 90.5691C43.8726 90.7635 43.6399 90.87 43.4011 90.87V90.87Z" fill="black"/>
<path d="M43.226 90.6964C42.9905 90.6964 42.7571 90.5934 42.5982 90.3955L32.4597 77.7658C32.0841 77.2979 32.0011 76.6298 32.2486 76.0637L33.7701 72.5891C33.774 72.5804 33.7753 72.5682 33.7753 72.5563L31.4959 71.4206C30.924 71.1358 30.5948 70.4555 30.6955 69.7661L31.3536 65.2575C31.4177 64.8179 31.8264 64.5128 32.2656 64.5775C32.7052 64.6415 33.01 65.0499 32.9456 65.4895L32.2875 69.9985C32.2866 70.0049 32.2862 70.011 32.2866 70.0165L34.566 71.1522C35.2859 71.5107 35.5903 72.4449 35.2444 73.2347L33.7231 76.7087C33.7164 76.7244 33.717 76.7569 33.7231 76.7705L43.8536 89.3879C44.1316 89.7345 44.0763 90.2407 43.7297 90.5191C43.5807 90.6381 43.403 90.6964 43.226 90.6964ZM32.2911 70.0455H32.2943H32.2911Z" fill="black"/>
<path d="M43.3831 72.2549C41.5001 72.2549 39.66 71.732 38.0606 70.743C37.6828 70.5094 37.5656 70.0135 37.7996 69.6357C38.0332 69.2575 38.5291 69.1407 38.907 69.3747C40.2518 70.2062 41.7994 70.6458 43.3831 70.6458C44.969 70.6458 46.5179 70.2056 47.8628 69.3724C48.2406 69.1388 48.7365 69.2553 48.9704 69.6328C49.2044 70.0106 49.0879 70.5065 48.7101 70.7405C47.1107 71.7313 45.2686 72.2549 43.3831 72.2549V72.2549Z" fill="black"/>
<path d="M66.6443 91.2013C62.2499 91.2013 58.1184 89.4902 55.0113 86.3827C48.5968 79.9683 48.5968 69.5308 55.0113 63.1164C58.1184 60.0089 62.2499 58.2979 66.6443 58.2979C71.0387 58.2979 75.1701 60.0089 78.2773 63.1164C81.3847 66.2235 83.0961 70.355 83.0961 74.7494C83.0961 79.1438 81.385 83.2753 78.2773 86.3824C75.1705 89.4898 71.039 91.2013 66.6443 91.2013V91.2013ZM66.6443 59.9066C62.6798 59.9066 58.9525 61.4504 56.1489 64.2537C50.362 70.0409 50.362 79.4576 56.1489 85.2448C58.9525 88.0481 62.6798 89.5919 66.6443 89.5919C70.609 89.5919 74.3363 88.0481 77.1396 85.2448C79.943 82.4414 81.4871 78.7142 81.4871 74.7494C81.4871 70.7846 79.943 67.0574 77.1396 64.254C74.3366 61.4507 70.6094 59.9066 66.6443 59.9066V59.9066Z" fill="black"/>
<path d="M56.898 18.4633C55.4499 18.2059 54.3235 17.0795 54.0339 15.6635C54.0339 15.5991 53.9052 15.5991 53.9052 15.6635C53.6155 17.0795 52.4892 18.2059 51.041 18.4633C50.9766 18.4633 50.9766 18.5598 51.041 18.592C52.4892 18.8495 53.6155 19.9758 53.9052 21.3918C53.9052 21.4562 54.0339 21.4562 54.0339 21.3918C54.3235 19.9758 55.4499 18.8495 56.898 18.592C56.9624 18.5598 56.9624 18.4633 56.898 18.4633Z" fill="#F5C84C"/>
<path d="M37.8946 12.8674C34.7324 12.337 32.273 10.0166 31.6405 7.09945C31.6405 6.96685 31.3595 6.96685 31.3595 7.09945C30.727 10.0166 28.2676 12.337 25.1054 12.8674C24.9649 12.8674 24.9649 13.0663 25.1054 13.1326C28.2676 13.663 30.727 15.9834 31.3595 18.9006C31.3595 19.0331 31.6405 19.0331 31.6405 18.9006C32.273 15.9834 34.7324 13.663 37.8946 13.1326C38.0351 13.0663 38.0351 12.8674 37.8946 12.8674Z" fill="#FC657E"/>
<path d="M76.5501 55.1784C75.7336 55.0332 75.0984 54.398 74.9349 53.5995C74.9349 53.5632 74.8621 53.5632 74.8621 53.5995C74.699 54.398 74.0637 55.0332 73.2469 55.1784C73.2106 55.1784 73.2106 55.2328 73.2469 55.2511C74.0634 55.3962 74.6987 56.0315 74.8621 56.8299C74.8621 56.8663 74.9349 56.8663 74.9349 56.8299C75.098 56.0315 75.7333 55.3962 76.5501 55.2511C76.5864 55.2328 76.5864 55.1784 76.5501 55.1784V55.1784Z" fill="#70D6F9"/>
<path d="M18.5698 52.7851H17.8296V52.0449C17.8296 51.4979 17.3791 51.0151 16.7998 51.0151C16.2205 51.0151 15.77 51.4657 15.77 52.0449V52.7851H15.0298C14.4827 52.7851 14 53.2357 14 53.8149C14 54.3942 14.4505 54.8447 15.0298 54.8447H15.77V55.5849C15.77 56.132 16.2205 56.6147 16.7998 56.6147C17.3791 56.6147 17.8296 56.1642 17.8296 55.5849V54.8447H18.5698C19.1169 54.8447 19.5996 54.3942 19.5996 53.8149C19.5996 53.2357 19.1491 52.7851 18.5698 52.7851Z" fill="#70D6F9"/>
<path d="M61.0816 27.1361C61.6859 27.1361 62.1758 26.6462 62.1758 26.0419C62.1758 25.4376 61.6859 24.9478 61.0816 24.9478C60.4773 24.9478 59.9874 25.4376 59.9874 26.0419C59.9874 26.6462 60.4773 27.1361 61.0816 27.1361Z" fill="#F5C84C"/>
<path d="M18.602 63.1796C19.2063 63.1796 19.6962 62.6897 19.6962 62.0854C19.6962 61.4811 19.2063 60.9912 18.602 60.9912C17.9977 60.9912 17.5078 61.4811 17.5078 62.0854C17.5078 62.6897 17.9977 63.1796 18.602 63.1796Z" fill="#B2E26D"/>
<path d="M66.8077 77.3309C66.3633 77.3309 66.0032 76.9708 66.0032 76.5264C66.0032 76.0819 66.3633 75.7218 66.8077 75.7218C66.9371 75.7218 67.0652 75.7189 67.1913 75.7138C69.0678 75.6324 70.5382 74.0696 70.5382 72.1561C70.5382 70.8547 71.2546 69.6723 72.408 69.0709C72.8019 68.8655 73.2878 69.0178 73.4935 69.412C73.6991 69.8059 73.5462 70.2918 73.152 70.4975C72.5322 70.8209 72.1473 71.4565 72.1473 72.1564C72.1473 74.934 70.0005 77.2028 67.2599 77.3216C67.1105 77.3274 66.9599 77.3309 66.8077 77.3309Z" fill="black"/>
<path d="M66.4814 77.3309C66.3289 77.3309 66.1782 77.3273 66.0299 77.3212C63.2883 77.2025 61.1418 74.9337 61.1418 72.1561C61.1418 71.4561 60.7569 70.8206 60.1371 70.4971C59.7429 70.2915 59.5903 69.8055 59.7956 69.4116C60.0013 69.0174 60.4876 68.8649 60.8811 69.0705C62.0345 69.6723 62.7509 70.8543 62.7509 72.1558C62.7509 74.0693 64.2213 75.632 66.0987 75.7134C66.2243 75.7189 66.352 75.7215 66.4814 75.7215C66.9258 75.7215 67.2859 76.0816 67.2859 76.526C67.2859 76.9705 66.9255 77.3309 66.4814 77.3309Z" fill="black"/>
<path d="M68.5343 70.0241H64.7549C64.3105 70.0241 63.9504 69.664 63.9504 69.2196C63.9504 68.7752 64.3105 68.415 64.7549 68.415H68.5343C68.9787 68.415 69.3389 68.7752 69.3389 69.2196C69.3389 69.664 68.9784 70.0241 68.5343 70.0241Z" fill="black"/>
<path d="M68.5343 82.3698H64.7549C64.3105 82.3698 63.9504 82.0097 63.9504 81.5653C63.9504 81.1209 64.3105 80.7607 64.7549 80.7607H68.5343C68.9787 80.7607 69.3389 81.1209 69.3389 81.5653C69.3389 82.0097 68.9784 82.3698 68.5343 82.3698Z" fill="black"/>
<path d="M66.6442 82.1182C66.1998 82.1182 65.8397 81.7581 65.8397 81.3137V69.4715C65.8397 69.0271 66.1998 68.667 66.6442 68.667C67.0886 68.667 67.4487 69.0271 67.4487 69.4715V81.3137C67.4487 81.7581 67.0886 82.1182 66.6442 82.1182Z" fill="black"/>
<path d="M66.6442 86.6562C60.0785 86.6562 54.737 81.3147 54.737 74.749C54.737 68.1833 60.0785 62.8418 66.6442 62.8418C73.2099 62.8418 78.5514 68.1833 78.5514 74.749C78.5514 81.3147 73.2102 86.6562 66.6442 86.6562ZM66.6442 64.4509C60.9657 64.4509 56.3461 69.0705 56.3461 74.749C56.3461 80.4274 60.9657 85.0471 66.6442 85.0471C72.3226 85.0471 76.9423 80.4274 76.9423 74.749C76.9423 69.0705 72.3226 64.4509 66.6442 64.4509Z" fill="black"/>
<path d="M66.6442 86.6562C60.0785 86.6562 54.737 81.3147 54.737 74.749C54.737 68.1833 60.0785 62.8418 66.6442 62.8418C73.2099 62.8418 78.5514 68.1833 78.5514 74.749C78.5514 81.3147 73.2102 86.6562 66.6442 86.6562ZM66.6442 64.4509C60.9657 64.4509 56.3461 69.0705 56.3461 74.749C56.3461 80.4274 60.9657 85.0471 66.6442 85.0471C72.3226 85.0471 76.9423 80.4274 76.9423 74.749C76.9423 69.0705 72.3226 64.4509 66.6442 64.4509Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_565_667">
<rect width="98" height="98" fill="white"/>
</clipPath>
</defs>
</svg>

  );
};

export default Psychologist;
