import React from 'react'
import ReactDOM from 'react-dom'
// STYLE
import './styles/index.scss'
import './index.css'
import './fonts/line-awesome-1.3.0/css/line-awesome.css'
//
import App from './App'
import reportWebVitals from './reportWebVitals'
import { persistor, store } from './app/store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

ReactDOM.render(
	<Provider store={store}>
		{/* <PersistGate loading={null} persistor={persistor}> */}
		<App />
		{/* </PersistGate> */}
	</Provider>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
