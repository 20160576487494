import Toast from "../containers/Toast/Toast";

type References = {
    toast: Toast | null
}

const references: References = {
    toast: null
}

export default references